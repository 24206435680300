import { gql } from '@apollo/client'

import { OBLIO_INVOICE_FIELDS_FRAGMENT } from './fragments'

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($createInvoiceToBookingInput: CreateInvoiceToBookingInput!) {
    createOblioInvoice(createInvoiceToBookingData: $createInvoiceToBookingInput) {
      ...OblioInvoiceFields
    }
  }
  ${OBLIO_INVOICE_FIELDS_FRAGMENT}
`

export const CANCEL_INVOICE = gql`
  mutation CancelInvoice(
    $seriesName: String!
    $number: String!
  ) {
    cancelOblioInvoice(
      seriesName: $seriesName
      number: $number  
    )
  }
`

export const RESTORE_INVOICE = gql`
  mutation RestoreInvoice(
    $seriesName: String!
    $number: String!
  ) {
    restoreOblioInvoice(
      seriesName: $seriesName
      number: $number  
    )
  }
`

export const STORNO_INVOICE = gql`
  mutation StornoInvoice(
    $seriesName: String!
    $number: String!
    $bookingId: Int!
  ) {
    stornoOblioInvoice(
      seriesName: $seriesName
      number: $number
      bookingId: $bookingId
    )
  }
`

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice(
    $seriesName: String!
    $number: String!
  ) {
    deleteOblioInvoice(
      seriesName: $seriesName
      number: $number  
    )
  }
`

export const COLLECT_INVOICE = gql`
  mutation CollectInvoice($collectInvoiceInput: CollectInvoiceInput!) {
    collectOblioInvoice(collectInvoiceData: $collectInvoiceInput)
  }
`

