import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { format } from 'date-fns'
import React, { useState } from 'react'

const InvoicePaymentDialog = ({ onClose, onPay, open, invoice }) => {
    const [issueDate, setIssueDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [type, setType] = useState('')
    const [documentNumber, setDocumentNumber] = useState('')
    const [mentions, setMentions] = useState(`Contravaloarea facturii ${invoice.seriesName}-${invoice.number}`)
    const [value, setValue] = useState(invoice.total)

    const handleOnClose = () => {
        onClose()
    }

    const handleOnPay = () => {
        onPay({
            issueDate,
            type,
            documentNumber,
            mentions,
            value,
            currency: invoice.currency
        })
    }

    const handleIssueDateChange = (e) => {
        setIssueDate(e.target.value)
    }

    const handleTypeChange = (e) => {
        setType(e.target.value)
    }

    const handleDocumentNumberChange = (e) => {
        setDocumentNumber(e.target.value)
    }

    const handleMentionsChange = (e) => {
        setMentions(e.target.value)
    }

    const handleValueChange = (e) => {
        setValue(e.target.value.length ? e.target.value : null)
    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            onClose={handleOnClose}
            aria-labelledby='simple-dialog-title'
            open={open}
        >
            <DialogTitle id='simple-dialog-title'>Payment Information</DialogTitle>
            <DialogContent>
                <br />
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField
                            size='small'
                            variant='outlined'
                            label='Issue Date'
                            type='date'
                            fullWidth
                            value={issueDate}
                            onChange={handleIssueDateChange}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl
                            size='small'
                            variant='outlined'
                            fullWidth
                        >
                            <InputLabel id='invoice-payment-method-label'>Payment Method</InputLabel>
                            <Select
                                labelId='invoice-payment-method-label'
                                id='invoice-payment-method'
                                value={type}
                                onChange={handleTypeChange}
                                label='Payment Method'
                            >
                                {
                                    [
                                        {
                                            id: 'Chitanta',
                                            name: 'Chitanta'
                                        },
                                        {
                                            id: 'Bon fiscal',
                                            name: 'Bon fiscal'
                                        },
                                        {
                                            id: 'Alta incasare numerar',
                                            name: 'Alta incasare numerar'
                                        },
                                        {
                                            id: 'Ordin de plata',
                                            name: 'Ordin de plata'
                                        },
                                        {
                                            id: 'Mandat postal',
                                            name: 'Mandat postal'
                                        },
                                        {
                                            id: 'Card',
                                            name: 'Card'
                                        },
                                        {
                                            id: 'CEC',
                                            name: 'CEC'
                                        },
                                        {
                                            id: 'Bilet ordin',
                                            name: 'Bilet ordin'
                                        },
                                        {
                                            id: 'Ramburs',
                                            name: 'Ramburs'
                                        },
                                        {
                                            id: 'Alta incasare banca',
                                            name: 'Alta incasare banca'
                                        }
                                    ].map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            id='payment-document-number'
                            label='Document Number'
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={documentNumber}
                            onChange={handleDocumentNumberChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id='payment-mentions'
                            label='Mentions'
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={mentions}
                            onChange={handleMentionsChange}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container alignContent='center' alignItems='center' spacing={1}>
                            <Grid item xs={5}>
                                <TextField
                                    id='payment-value'
                                    label='Value'
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    value={value}
                                    onChange={handleValueChange}
                                />
                            </Grid>
                            <Grid item>
                                {invoice.currency}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions disableSpacing sx={{
                px: 3,
                pb: 3
            }}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleOnPay}>
                    Pay
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvoicePaymentDialog
