import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'


import { GET_BOOKING_ITEM_PRICE_ESTIMATION } from '../../graphql/bookings/queries'
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

const BookingItemPrice = ({ item, onChange, disabled, onChangeCurrency }) => {
  const [amount, setAmount] = useState(item.amount)
  const [currency, setCurrency] = useState(item.currency)
  const { enqueueSnackbar } = useSnackbar()

  const [getBookingItemPrice, { loading }] = useLazyQuery(
    GET_BOOKING_ITEM_PRICE_ESTIMATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ bookingItemPriceEstimation }) => {
        setAmount(bookingItemPriceEstimation.amount)
        setCurrency(bookingItemPriceEstimation.currency)
        onChange(bookingItemPriceEstimation.amount, item)

        enqueueSnackbar('Price was estimated and changed accordingly', {
          variant: 'warning',
        })
      },
      onError: (error) => {
        enqueueSnackbar(`Cannot estimate price: ${error.message}`, {
          variant: 'error',
        })
      },
    }
  )

  const handleOnEstimateItem = (item) => {
    if (item.type === 'ACCOMMODATION') {
      getBookingItemPrice({
        variables: {
          type: 'ACCOMMODATION',
          currency,
          pax: parseInt(item.pax, 10),
          checkIn: item.checkIn,
          checkOut: item.checkOut,
          accommodationId: item.accommodation.id,
        },
      })
    }

    if (item.type === 'TOUR') {
      getBookingItemPrice({
        variables: {
          type: 'TOUR',
          currency,
          date: item.date,
          pax: parseInt(item.pax, 10),
          tourId: item.tour.id,
        },
      })
    }
  }

  const handleOnChangeAmount = (amount, item) => {
    setAmount(amount)
    onChange(amount, item)
  }

  const handleOnChangeCurrency = (currency, item) => {
    setCurrency(currency)
    onChangeCurrency(currency, item)
  }

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12} sm={4}>
        <TextField
          size='small'
          variant='outlined'
          label='Price'
          type='number'
          value={amount}
          fullWidth
          disabled={loading || disabled}
          onChange={(e) => handleOnChangeAmount(e.target.value, item)}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl
          size='small'
          variant='outlined'
          fullWidth
          disabled={loading || disabled}
        >
          <InputLabel id='select-currency-label'>Currency</InputLabel>
          <Select
            labelId='select-currency-label'
            id='select-currency'
            value={currency}
            label='Currency'
            onChange={(e) => handleOnChangeCurrency(e.target.value, item)}
          >
            <MenuItem value={'EUR'}>EUR</MenuItem>
            <MenuItem value={'RON'}>RON</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        {loading && <CircularProgress size={20} />}
        {!loading && (
          <Button
            size='small'
            disabled={disabled}
            aria-label='delete'
            onClick={() => handleOnEstimateItem(item)}
          >
            Estimate
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default BookingItemPrice
