import { gql } from '@apollo/client'
import { PRICE_FIELDS_FRAGMENT } from '../prices/fragments'

export const ACCOMMODATION_FIELDS_FRAGMENT = gql`
  fragment AccommodationFields on Accommodation {
    id
    status
    name
    group
    calendarUrl
    bookingComCalendarUrl
    airbnbCalendarUrl
    travelminitCalendarUrl
    createdAt
    updatedAt
  }
`

export const ACCOMMODATION_FIELDS_WITH_PRICE_RELATION_FRAGMENT = gql`
  fragment AccommodationFieldsWithPriceRelation on Accommodation {
    ...AccommodationFields
    prices {
      priceToAccommodationId
      amount
      price {
        ...PriceFields
      }
    }
  }
  ${ACCOMMODATION_FIELDS_FRAGMENT}
  ${PRICE_FIELDS_FRAGMENT}
`
