import { createContext, useContext, useState } from 'react'

export const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext)

export const SettingsProvider = ({ children }) => {
  const [title, setTitle] = useState('');

  return (
    <SettingsContext.Provider value={{ title, setTitle }}>
      {children}
    </SettingsContext.Provider>
  );
}