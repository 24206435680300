import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { Button, ButtonGroup, Divider, Grid, TextField } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import { CREATE_TOUR } from '../../../graphql/tours/mutations'

import SectionCard from '../../card/section-card'
import PriceList from '../../price/price-list'

const AddTourForm = ({ prices }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [tour, setTour] = useState({
    name: '',
    personMin: 3,
    personMax: 8,
    prices: [],
  })

  const [createTour] = useMutation(CREATE_TOUR, {
    onCompleted({ createTour }) {
      enqueueSnackbar(`Successfully created ID #${createTour.id}`, {
        variant: 'success',
      })

      navigate(`/tours/edit/${createTour.id}/`)
    },
    onError: (error) => {
      enqueueSnackbar(`Update error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const handleSave = async () => {
    const tourInput = {
      name: tour.name,
      personMin: parseInt(tour.personMin, 10),
      personMax: parseInt(tour.personMax, 10),
    }

    const prices = []

    tour.prices
      .filter((p) => p.amount)
      .forEach((p) => {
        prices.push({
          priceId: p.price.id,
          amount: p.amount,
        })
      })

    if (prices) {
      tourInput.prices = prices
    }

    await createTour({
      variables: {
        tourInput,
      },
    })
  }

  const handleBack = () => {
    navigate(`/tours/`)
  }

  const handleAddNewPriceMapping = (price) => {
    tour.prices.push({
      priceToTourId: `new-${price.id}`,
      amount: null,
      price,
    })

    setTour({ ...tour })
  }

  const handleDeletePriceMapping = (price) => {
    const index = tour.prices.findIndex(
      (p) => p.priceToTourId === price.priceToTourId
    )

    if (index !== -1) {
      tour.prices.splice(index, 1)
    }

    setTour({ ...tour })
  }

  const handleChangePriceMapping = (price, newAmount) => {
    tour.prices.map((p) => {
      if (p.priceToTourId === price.priceToTourId) {
        p.amount = parseInt(newAmount, 10)
      }

      return p
    })

    setTour({ ...tour })
  }

  const handleChangeName = (e) => {
    tour.name = e.target.value
    setTour({ ...tour })
  }

  const handleChangePersonMin = (e) => {
    tour.personMin = e.target.value
    setTour({ ...tour })
  }

  const handleChangePersonMax = (e) => {
    tour.personMax = e.target.value
    setTour({ ...tour })
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item>
          <ButtonGroup
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            <Button aria-label='return-back' onClick={handleBack}>
              <ChevronLeftRoundedIcon />
            </Button>
            <Button onClick={handleSave}>
              <SaveRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid
        container
        justifyContent='space-between'
        spacing={2}
        direction='row'
      >
        <Grid item xs={12} md={6}>
          <SectionCard title='Basic Info' variant='outlined'>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  label='Name'
                  size='small'
                  variant='outlined'
                  fullWidth
                  defaultValue={tour.name}
                  onChange={handleChangeName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Person Min.'
                  fullWidth
                  defaultValue={tour.personMin}
                  onChange={handleChangePersonMin}
                  type='number'
                  inputProps={{ min: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Person Max.'
                  fullWidth
                  defaultValue={tour.personMax}
                  onChange={handleChangePersonMax}
                  type='number'
                  inputProps={{ min: 1 }}
                />
              </Grid>
            </Grid>
          </SectionCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionCard title='Prices' variant='outlined'>
            <PriceList
              mappingKey='priceToTourId'
              priceMappings={tour.prices}
              prices={prices}
              onAddNewPriceMapping={handleAddNewPriceMapping}
              onDeletePriceMapping={handleDeletePriceMapping}
              onChangePriceMapping={handleChangePriceMapping}
            />

          </SectionCard>
        </Grid>
      </Grid>
    </>
  )
}

export default AddTourForm
