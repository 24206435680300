import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'

import { PageLoader } from '../../components/page-loader'

import { useSettings } from '../../providers/settings-context-provider'
import { GET_INVOICE_WITH_NOMENCLATURE_SERIES } from '../../graphql/invoices/queries'
import EditInvoiceForm from '../../components/forms/invoices/edit-invoice-form'

const EditInvoicePage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Edit Invoice'), [setTitle])
  const { seriesNameNumber } = useParams()

  const { loading, error, data, refetch } = useQuery(GET_INVOICE_WITH_NOMENCLATURE_SERIES, {
    fetchPolicy: 'network-only',
    variables: {
      seriesName: seriesNameNumber.split('-')[0],
      number: seriesNameNumber.split('-')[1]
    },
  })

  if (loading) return <PageLoader />
  if (error) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <EditInvoiceForm
        invoice={data.oblioInvoice}
        nomenclatureSeries={data.oblioNomenclatureSeries}
        refetchInvoice={refetch}
      />
    </Container>
  )
}

export default EditInvoicePage
