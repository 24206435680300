import React from "react";

const CallbackPage = () => {
    return (
        <div className="page-layout">
            <div className="page-layout__content" />
        </div>
    );
};

export default CallbackPage