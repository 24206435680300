import { gql } from '@apollo/client'

import { BOOKING_FIELDS_FRAGMENT } from './fragments'
import { PRICE_FIELDS_FRAGMENT } from '../prices/fragments'

export const GET_BOOKINGS = gql`
  query GetBookings(
    $skip: Int
    $take: Int    
    $orderBy: BookingsOrderBy!
    $orderDirection: BookingsOrderDirection!
    $id: Float
    $status: BookingStatus
    $source: String
    $hasInvoice: Boolean
    $checkInAfter: DateTime
    $checkInBefore: DateTime
    $checkOutAfter: DateTime
    $checkOutBefore: DateTime
    $name: String
    $dateFrom: DateTime
    $dateTo: DateTime
    $checkIn: DateTime
    $checkOut: DateTime
  ) {
    bookings(
      skip: $skip
      take: $take
      orderBy: $orderBy
      orderDirection: $orderDirection
      id: $id
      status: $status
      source: $source
      hasInvoice: $hasInvoice
      checkInAfter: $checkInAfter
      checkInBefore: $checkInBefore
      checkOutAfter: $checkOutAfter
      checkOutBefore: $checkOutBefore
      name: $name
      dateFrom: $dateFrom
      dateTo: $dateTo
      checkIn: $checkIn
      checkOut: $checkOut
    ) {
      ...BookingFields
    }
  }
  ${BOOKING_FIELDS_FRAGMENT}
`

export const GET_BOOKING = gql`
  query GetBooking($id: Int!) {
    booking(id: $id) {
      ...BookingFields
    }    
  }
  ${BOOKING_FIELDS_FRAGMENT}  
`

export const GET_BOOKING_ITEM_PRICE_ESTIMATION = gql`
  query GetBookingItemPriceEstimation(
    $type: BookingType!
    $currency: String!
    $pax: Int
    $checkIn: DateTime
    $checkOut: DateTime
    $date: DateTime
    $tourId: Int
    $accommodationId: Int
  ) {
    bookingItemPriceEstimation(
      type: $type
      currency: $currency
      pax: $pax
      checkIn: $checkIn
      checkOut: $checkOut
      date: $date
      tourId: $tourId
      accommodationId: $accommodationId
    ) {
      amount
      currency
      units {
        date
        ppu
        currency
        price {
          ...PriceFields
        }
      }
    }
  }
  ${PRICE_FIELDS_FRAGMENT}
`
