import { TextField } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { NumericFormat } from 'react-number-format'

const PriceInput = (props) => {
  const { currency, onChange } = props

  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState(props.value)

  const inputRef = useRef()

  const handleClick = (e) => {
    if (inputRef.current.contains(e.target)) {
      setEditMode(true)
      return
    }
    // outside click
    setEditMode(false)
  }

  const handleChange = (e) => {
    setValue(e.target.value)

    onChange(e.target.value)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <div ref={inputRef}>
      {!editMode && (
        <>
          {value && (
            <NumericFormat
              value={value}
              onClick={() => setEditMode(!editMode)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${currency} `}
              decimalScale='2'
              fixedDecimalScale
            />
          )}
          {value === null && `${currency} ?`}
        </>
      )}
      {editMode && (
        <TextField
          size='small'
          variant='outlined'
          type='number'
          label='Standard'
          defaultValue={value}
          onChange={handleChange}
        />
      )}
    </div>
  )
}

export default PriceInput
