import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'

const SectionCard = (props) => {
  return (
    <Card {...props}
      sx={{
        overflow: 'visible',
        position: 'relative',
      }}>
      {props.title && (
        <Box sx={{
          position: 'absolute',
          top: -8,
          left: 10,
        }}>
          <Typography
            sx={{
              fontSize: 10,
              fontWeight: 600,
              color: '#aaa',
              textTransform: 'uppercase',
              textShadow: '#fff 1px 1px 0px, #fff -1px -1px 0px',
              position: 'relative',
              padding: '0 5px',
              zIndex: 2,
            }}
            color='textSecondary'
            gutterBottom
          >
            {props.title}
          </Typography>
          <Box
            component="span"
            sx={{
              height: 1,
              width: '100%',
              position: 'absolute',
              backgroundColor: '#fff',
              top: '-1px',
              left: 0,
              zIndex: 1,
            }} />
        </Box>
      )}
      <CardContent
        sx={{
          padding: 1,
          paddingTop: 2,
        }}>
        {props.children}
      </CardContent>
    </Card>
  )
}

export default SectionCard
