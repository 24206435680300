import React, { useEffect, useState } from 'react'
import { format, lightFormat } from 'date-fns'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Container, Grid } from '@mui/material'

import { GET_PRICES } from '../../graphql/prices/queries'

import EnhancedTable from '../../components/table/enhanced-table'
import { PageLoader } from '../../components/page-loader'
import TableHead from '../../components/table/table-head'
import { useSettings } from '../../providers/settings-context-provider'


const PricesListPage = () => {
  const rowsPerPage = 25
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Prices'), [setTitle])

  const [prices, setPrices] = useState([])

  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const sortBy = 'ID'
  const sortDirection = 'asc'

  const { error, data, fetchMore, refetch, networkStatus } = useQuery(GET_PRICES, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      take: rowsPerPage,
      orderBy: sortBy.toUpperCase(),
      orderDirection: sortDirection.toUpperCase()
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (data) {
      setPrices(data.prices)
    }
  }, [data])

  const handleRowClick = (e, id) => {
    e.stopPropagation()

    navigate(`/prices/edit/${id}`)
  }

  const handleCreate = () => {
    navigate(`/prices/add`)
  }

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchMore({
      variables: {
        skip: (page + 1) * rowsPerPage,
        take: rowsPerPage
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        prices: [
          ...previousResult.prices, ...fetchMoreResult.prices
        ]
      }),
    })
  }

  const handleSort = (newSortBy, newSortDirection) => {
    setPage(0)
    refetch({
      orderBy: newSortBy.toUpperCase(),
      orderDirection: newSortDirection.toUpperCase(),
      skip: 0,
      take: rowsPerPage,
    })
  }

  const renderDetails = (price) => {
    const dateFrom = format(price.dateFrom, 'dd.MM')
    const dateTo = format(price.dateTo, 'dd.MM')

    const reservationDateFrom = format(price.reservationDateFrom, 'dd.MM')
    const reservationDateTo = format(price.reservationDateTo, 'dd.MM')

    const paxFrom = price.paxFrom
    const paxTo = price.paxTo > 100 ? '∞' : price.paxTo

    const nightsFrom = price.nightsFrom
    const nightsTo = price.nightsTo > 100 ? '∞' : price.nightsTo

    return (
      <Grid container direction='column'>
        <Grid item>{`Booking ${dateFrom} - ${dateTo}`}</Grid>
        <Grid item>
          {`Reservation ${reservationDateFrom} - ${reservationDateTo}`}
        </Grid>
        {price.type === 'PAX' && (
          <Grid item>
            {paxFrom}&nbsp;-&nbsp;{paxTo} pax
          </Grid>
        )}
        {price.type === 'NIGHT' && (
          <>
            <Grid item>
              {nightsFrom}&nbsp;-&nbsp;{nightsTo} nights
            </Grid>
            <Grid item>
              {paxFrom}&nbsp;-&nbsp;{paxTo} pax
            </Grid>
          </>
        )}
      </Grid>
    )
  }

  if (networkStatus === NetworkStatus.loading) return <PageLoader />
  if (error) return <div>Error!</div>

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      enableSorting: true,
      label: 'ID',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Description',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Type',
    },
    {
      id: 'currency',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Currency',
    },
    {
      id: 'details',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Details',
      virtual: true,
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Created',
    },
    {
      id: 'updatedAt',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Updated',
    },
  ]

  const rows = prices.map((price) => {
    return {
      data: price,
      id: {
        sortValue: price.id,
        displayValue: price.id,
      },
      description: {
        sortValue: price.description,
        displayValue: price.description,
      },
      type: {
        sortValue: price.type,
        displayValue: price.type,
      },
      currency: {
        sortValue: price.currency,
        displayValue: price.currency,
      },
      details: {
        sortValue: '',
        displayValue: renderDetails(price),
      },
      createdAt: {
        sortValue: price.createdAt,
        displayValue: lightFormat(new Date(price.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      },
      updatedAt: {
        sortValue: price.updatedAt,
        displayValue: lightFormat(new Date(price.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
      },
    }
  })

  return (
    <Container maxWidth={false} disableGutters>
      <TableHead
        handleCreate={handleCreate} />
      <EnhancedTable
        cells={headCells}
        rows={rows}
        onRowClick={handleRowClick}
        onSort={handleSort}
        onLoadMore={handleLoadMore}
        sortBy={sortBy}
        sortDirection={sortDirection}
        loading={networkStatus === NetworkStatus.fetchMore}
      />
    </Container>
  )
}

export default PricesListPage
