import React, { useEffect, useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { GET_ACCOMMODATIONS } from '../../graphql/accommodations/queries'

import EnhancedTable from '../../components/table/enhanced-table'
import AccommodationGroupChip from '../../components/chip/accommodation-group-chip'
import { Container } from '@mui/material'
import { PageLoader } from '../../components/page-loader'
import { lightFormat } from 'date-fns'
import TableHead from '../../components/table/table-head'
import { useSettings } from '../../providers/settings-context-provider'
import AccommodationStatusChip from '../../components/chip/accommodation-status-chip'

const AccommodationsListPage = (props) => {
  const rowsPerPage = 25
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Accommodations'), [setTitle])

  const [accommodations, setAccommodations] = useState([])

  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const sortBy = 'ID'
  const sortDirection = 'asc'

  const { error, data, fetchMore, refetch, networkStatus } = useQuery(GET_ACCOMMODATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      take: rowsPerPage,
      orderBy: sortBy.toUpperCase(),
      orderDirection: sortDirection.toUpperCase()
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (data) {
      setAccommodations(data.accommodations)
    }
  }, [data])

  const handleRowClick = (e, id) => {
    e.stopPropagation()

    navigate(`/accommodations/edit/${id}`)
  }

  const handleCreate = () => {
    navigate(`/accommodations/add`)
  }

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchMore({
      variables: {
        skip: (page + 1) * rowsPerPage,
        take: rowsPerPage
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        accommodations: [
          ...previousResult.accommodations, ...fetchMoreResult.accommodations
        ]
      }),
    })
  }

  const handleSort = (newSortBy, newSortDirection) => {
    setPage(0)
    refetch({
      orderBy: newSortBy.toUpperCase(),
      orderDirection: newSortDirection.toUpperCase(),
      skip: 0,
      take: rowsPerPage,
    })
  }

  if (networkStatus === NetworkStatus.loading) return <PageLoader />
  if (error) return <div>Error!</div>

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      enableSorting: true,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Name',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Status',
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Group',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Created',
    },
    {
      id: 'updatedAt',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Updated',
    },
  ]

  const rows = accommodations.map((accommodation) => {
    return {
      data: accommodation,
      id: {
        sortValue: accommodation.id,
        displayValue: accommodation.id,
      },
      name: {
        sortValue: accommodation.name,
        displayValue: accommodation.name,
      },
      status: {
        sortValue: accommodation.status,
        displayValue: (
          <AccommodationStatusChip size='small' status={accommodation.status} />
        )
      },
      group: {
        sortValue: accommodation.group,
        displayValue: (
          <AccommodationGroupChip size='small' group={accommodation.group} />
        ),
      },
      createdAt: {
        sortValue: accommodation.createdAt,
        displayValue: lightFormat(new Date(accommodation.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      },
      updatedAt: {
        sortValue: accommodation.updatedAt,
        displayValue: lightFormat(new Date(accommodation.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
      },
    }
  })

  return (
    <Container maxWidth={false} disableGutters>
      <TableHead handleCreate={handleCreate} />
      <EnhancedTable
        cells={headCells}
        rows={rows}
        onRowClick={handleRowClick}
        onSort={handleSort}
        onLoadMore={handleLoadMore}
        sortBy={sortBy}
        sortDirection={sortDirection}
        loading={networkStatus === NetworkStatus.fetchMore}
      />
    </Container>
  )
}

export default AccommodationsListPage
