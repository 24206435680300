import { TextField } from '@mui/material'
import React, { useState } from 'react'

const DateInput = ({ field, label, value, onChange }) => {
  const [currentValue, setCurrentValue] = useState(value)

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
    onChange(e.target.value)
  };
  return (
    <TextField
      id={`date-input-${field}`}
      size='small'
      variant='outlined'
      label={label}
      type='date'
      InputLabelProps={{ shrink: true }}
      fullWidth
      value={currentValue}
      onChange={handleChange}
    />
  )
}


export default DateInput
