import { gql } from '@apollo/client'
import { CUSTOMER_FIELDS_FRAGMENT } from './fragments'

export const GET_CUSTOMERS = gql`
  query GetCustomers(
    $skip: Int
    $take: Int
    $name: String
    $type: CustomerType
    $orderBy: CustomersOrderBy!
    $orderDirection: CustomersOrderDirection!
  ) {
    customers(skip: $skip, take: $take, name: $name, type: $type, orderBy: $orderBy, orderDirection: $orderDirection) {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FIELDS_FRAGMENT}
`

export const GET_CUSTOMER = gql`
  query GetCustomer($id: Int!) {
    customer(id: $id) {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FIELDS_FRAGMENT}
`
