import React, { useState } from 'react'

import { format } from 'date-fns'
import { Button, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import PriceInput from './price-input'
const PriceList = ({
  mappingKey,
  priceMappings,
  prices,
  disabled = false,
  onAddNewPriceMapping,
  onDeletePriceMapping,
  onChangePriceMapping,
}) => {
  const [priceMenuAnchorEl, setPriceMenuAnchorEl] = useState(null)

  const handleClickOpenPriceMenu = (event) => {
    setPriceMenuAnchorEl(event.currentTarget)
  }

  const handleClosePriceMenu = () => {
    setPriceMenuAnchorEl(null)
  }

  const handleAddPrice = (price) => {
    setPriceMenuAnchorEl(null)
    onAddNewPriceMapping(price)
  }

  const getPriceType = (price) => {
    const dateFrom = format(price.dateFrom, 'dd.MM')
    const dateTo = format(price.dateTo, 'dd.MM')

    const paxFrom = price.paxFrom
    const paxTo = price.paxTo > 100 ? '∞' : price.paxTo

    const nightsFrom = price.nightsFrom
    const nightsTo = price.nightsTo > 100 ? '∞' : price.nightsTo

    if (price.type === 'PAX') {
      return `${price.description} - ${price.type} ${paxFrom}-${paxTo} persons between ${dateFrom} and ${dateTo}`
    } else if (price.type === 'NIGHT') {
      return `${price.description} - ${price.type} ${nightsFrom}-${nightsTo} nights, ${paxFrom}-${paxTo} persons between ${dateFrom} and ${dateTo}`
    }
    return `${price.description} - ${price.type} between ${dateFrom} and ${dateTo}`
  }

  const renderDetails = (price) => {
    const dateFrom = format(price.dateFrom, 'dd.MM')
    const dateTo = format(price.dateTo, 'dd.MM')

    const paxFrom = price.paxFrom
    const paxTo = price.paxTo > 100 ? '∞' : price.paxTo

    const nightsFrom = price.nightsFrom
    const nightsTo = price.nightsTo > 100 ? '∞' : price.nightsTo

    return (
      <Grid container direction='column'>
        <Grid item>
          {dateFrom}&nbsp;-&nbsp;{dateTo}
        </Grid>
        {price.type === 'PAX' && (
          <Grid item>
            {paxFrom}&nbsp;-&nbsp;{paxTo} pax
          </Grid>
        )}
        {price.type === 'NIGHT' && (
          <>
            <Grid item>
              {nightsFrom}&nbsp;-&nbsp;{nightsTo} nights
            </Grid>
            <Grid item>
              {paxFrom}&nbsp;-&nbsp;{paxTo} pax
            </Grid>
          </>
        )}
      </Grid>
    )
  }

  const usedPricesIds = priceMappings.map((p) => p.price.id)

  const selectablePrices = prices.filter((p) => !usedPricesIds.includes(p.id))

  return (
    <>
      <TableContainer>
        <Table aria-label='price table'>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Details</TableCell>
              <TableCell align='right'>Amount</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priceMappings.map((p) => (
              <TableRow key={p[mappingKey]}>
                <TableCell component='th' scope='row'>
                  {p.price.description}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {renderDetails(p.price)}
                </TableCell>
                <TableCell align='right'>
                  <PriceInput
                    disabled={disabled}
                    value={p.amount}
                    currency={p.price.currency}
                    onChange={(newAmount) => onChangePriceMapping(p, newAmount)}
                  />
                </TableCell>

                <TableCell align='right'>
                  <IconButton
                    disabled={disabled}
                    onClick={() => onDeletePriceMapping(p)}
                    aria-label='delete-price'
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Button
        variant='outlined'
        aria-label='add-price'
        fullWidth
        color='secondary'
        onClick={handleClickOpenPriceMenu}
        disabled={selectablePrices.length <= 0 || disabled}
      >
        Add Price
      </Button>
      {selectablePrices.length > 0 && (
        <Menu
          keepMounted
          anchorEl={priceMenuAnchorEl}
          open={Boolean(priceMenuAnchorEl)}
          onClose={handleClosePriceMenu}
        >
          {selectablePrices.map((price) => (
            <MenuItem
              key={`price-${price.id}`}
              onClick={() => handleAddPrice(price)}
            >
              {getPriceType(price)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default PriceList
