import { gql } from '@apollo/client'

export const PRICE_FIELDS_FRAGMENT = gql`
  fragment PriceFields on Price {
    id
    description
    type
    currency
    paxFrom
    paxTo
    dateFrom
    dateTo
    reservationDateFrom
    reservationDateTo
    nightsFrom
    nightsTo
    createdAt
    updatedAt
  }
`
