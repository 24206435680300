import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'

const CustomSelect = ({ field, label, value, options, onChange }) => {
  const [selected, setSelected] = useState(value)

  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e.target.value)
  };
  return (
    <FormControl
      size='small'
      variant='outlined'
      fullWidth
    >
      <InputLabel id={`select-${field}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`select-${field}-label`}
        id={`select-${field}`}
        value={selected}
        label={label}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem
            key={`opt_${option.value}`}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}


export default CustomSelect
