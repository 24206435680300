import React, { useState } from 'react'

import { Box, Button, Collapse, Grid, IconButton, TextField, Tooltip } from '@mui/material'

import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import FilterListOffRoundedIcon from '@mui/icons-material/FilterListOffRounded';
import AddIcon from '@mui/icons-material/Add';

import CustomSelect from '../input/custom-select';
import DateInput from '../input/date-input';


function DebounceTextField(props) {
  const { handleDebounce, filter, debounceTimeout, ...rest } = props;

  const timerRef = React.useRef();

  const handleChange = (event) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(filter, event.target.value);
    }, debounceTimeout);
  };

  return <TextField {...rest} onChange={handleChange} />;
}

const TableHead = ({
  filters = [],
  handleFilter = () => { },
  handleCreate = null,
}) => {
  const [showFilters, setShowFilters] = useState(false)


  const onFilter = (filter, value) => {
    if (filter.type === 'number') {
      value = parseFloat(value)
    }

    handleFilter(filter.field, value)

    filter.default = value
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        alignItems={'center'}
        sx={{
          marginBottom: 2,
        }}
      >
        {handleCreate && <Grid item>
          <Button
            size='small'
            color='secondary'
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={handleCreate}
          >
            Add new
          </Button>
        </Grid>}

        {filters.length > 0 && (
          <Grid item sx={{ marginLeft: 'auto' }}>
            <Tooltip sx={{
              marginLeft: 'auto'
            }}
              title='Filters'>
              <IconButton
                onClick={() => setShowFilters(!showFilters)}
                aria-label='filter list'>
                {showFilters ? <FilterListOffRoundedIcon /> : <FilterListRoundedIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>




      {filters.length > 0 && (
        <Collapse in={showFilters}>
          <Box>
            <Grid container direction='row' spacing={2}>
              {filters.map((filter) => (
                <Grid item key={`${filter.field}`} xs={12}>
                  {(filter.type === 'number' || filter.type === 'text') && (
                    <DebounceTextField
                      size='small'
                      variant='outlined'
                      label={filter.label}
                      type={filter.type}
                      filter={filter}
                      fullWidth
                      debounceTimeout={200}
                      handleDebounce={onFilter}
                    />
                  )}

                  {filter.type === 'date' && (
                    <DateInput
                      field={filter.field}
                      label={filter.label}
                      value={filter.default}
                      onChange={(value) => onFilter(filter, value)}
                    />
                  )}

                  {filter.type === 'select' && filter.options.length && (
                    <CustomSelect
                      field={filter.field}
                      label={filter.label}
                      value={filter.default}
                      options={filter.options}
                      onChange={(value) => onFilter(filter, value)}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
          <br />
        </Collapse>
      )}
    </>
  )
}


export default TableHead
