import { gql } from '@apollo/client'

import { OBLIO_INVOICE_FIELDS_FRAGMENT, OBLIO_NOMENCLATURE_SERIES_FIELDS } from './fragments'

export const GET_INVOICES = gql`
  query GetInvoices(
    $skip: Int
    $take: Int    
    $orderBy: OblioInvoiceOrderBy!
    $orderDirection: OblioInvoiceOrderDirection!
    $id: Int = null
    $seriesName: String = null
    $number: String = null
  ) {
    oblioInvoices(
      skip: $skip
      take: $take
      orderBy: $orderBy
      orderDirection: $orderDirection
      id: $id
      seriesName: $seriesName
      number: $number
    ) {
      ...OblioInvoiceFields
    }    
  }
  ${OBLIO_INVOICE_FIELDS_FRAGMENT}
`

export const GET_INVOICE_WITH_NOMENCLATURE_SERIES = gql`
  query GetInvoice(
    $seriesName: String!
    $number: String!
  ) {
    oblioInvoice(
      seriesName: $seriesName
      number: $number
    ) {
      ...OblioInvoiceFields
    }
    oblioNomenclatureSeries{
      ...OblioNomenclatureSeriesFields      
    }
  }
  ${OBLIO_INVOICE_FIELDS_FRAGMENT}
  ${OBLIO_NOMENCLATURE_SERIES_FIELDS}
`
