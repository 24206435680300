import React, { useEffect, useState } from 'react';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { useAutocomplete } from '../../hooks/useAutocomplete';


const Autocomplete = (props) => {
    const { indices, refine } = useAutocomplete();
    const [inputValue, setInputValue] = useState('');
    const [currentOptions, setCurrentOptions] = useState([]);

    useEffect(() => {
        setCurrentOptions(indices[0].hits)
        // eslint-disable-next-line
    }, [indices[0].hits])


    useEffect(() => {
        refine(inputValue)
        // eslint-disable-next-line
    }, [inputValue])

    return (
        <MuiAutocomplete
            {...props}
            options={currentOptions}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        />
    );
};

export default Autocomplete;
