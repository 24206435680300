import React, { useState } from 'react'

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HotelRoundedIcon from '@mui/icons-material/HotelRounded';
import DirectionsBusRoundedIcon from '@mui/icons-material/DirectionsBusRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';

import CollapseableListItem from './collapseable-list-item'
import { List } from '@mui/material';

const Navigation = (props) => {
  const [menuItems] = useState([
    {
      label: 'Dashboard',
      icon: <HomeRoundedIcon />,
      path: '/dashboard',
    },
    {
      label: 'Accommodations',
      icon: <HotelRoundedIcon />,
      path: '/accommodations',
    },
    {
      label: 'Tours',
      icon: <DirectionsBusRoundedIcon />,
      path: '/tours',
    },
    {
      label: 'Prices',
      icon: <EuroRoundedIcon />,
      path: '/prices',
    },
    {
      label: 'Customers',
      icon: <GroupsRoundedIcon />,
      path: '/customers',
    },
    {
      label: 'Bookings',
      icon: <AddShoppingCartRoundedIcon />,
      path: '/bookings',
    },
    {
      label: 'Invoices',
      icon: <DescriptionRoundedIcon />,
      path: '/invoices',
    },
    {
      label: 'Calendar',
      icon: <CalendarTodayRoundedIcon />,
      path: '/calendar',
    },
    {
      label: 'Availability',
      icon: <EventAvailableRoundedIcon />,
      path: '/availability',
    },
  ])

  return (
    <List>
      {menuItems.map((item, index) => (
        <CollapseableListItem key={index} {...item} />
      ))}
    </List>
  )
}

export default Navigation
