import React, { useEffect, useState } from 'react'
import { useSettings } from '../../providers/settings-context-provider'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import { getAccommodationGroupName } from '../../components/chip/accommodation-group-chip'
import { useQuery } from '@apollo/client'
import { GET_BOOKINGS } from '../../graphql/bookings/queries'
import { format } from 'date-fns'
import BookingSourceChip from '../../components/chip/booking-source-chip'
import { Link } from 'react-router-dom'

const DashboardPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Dashboard'), [setTitle])
  const { user } = useAuth0()
  const [groupedCheckInsToday, setGroupedCheckInsToday] = useState([])
  const [newCheckInsCount, setNewCheckInsCount] = useState(0)

  const [groupedCheckOutsToday, setGroupedCheckOutsToday] = useState([])
  const [newCheckOutsCount, setNewCheckOutsCount] = useState(0)

  const { data: dataCheckInsToday } = useQuery(GET_BOOKINGS, {
    variables: {
      skip: 0,
      take: 1000,
      orderBy: 'ID',
      orderDirection: 'DESC',
      checkIn: format(new Date(), 'yyyy-MM-dd'),
      status: 'APPROVED'
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: dataCheckOutsToday } = useQuery(GET_BOOKINGS, {
    variables: {
      skip: 0,
      take: 1000,
      orderBy: 'ID',
      orderDirection: 'DESC',
      checkOut: format(new Date(), 'yyyy-MM-dd'),
      status: 'APPROVED'
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (dataCheckInsToday?.bookings) {
      const groups = {}
      let newCheckInsCount = 0

      dataCheckInsToday.bookings.forEach((booking) => {
        if (booking.status === 'CANCELED') {
          return
        }

        booking.items.forEach((bookingItem) => {
          if (bookingItem.type !== 'ACCOMMODATION') {
            return
          }

          if (!groups[bookingItem.accommodation.group]) {
            groups[bookingItem.accommodation.group] = []
          }

          groups[bookingItem.accommodation.group].push({
            booking,
            bookingItem
          })

          newCheckInsCount++
        })
      })


      setGroupedCheckInsToday(groups)
      setNewCheckInsCount(newCheckInsCount)
    }
  }, [dataCheckInsToday])

  useEffect(() => {
    if (dataCheckOutsToday?.bookings) {
      const groups = {}
      let newCheckOutsCount = 0

      dataCheckOutsToday.bookings.forEach((booking) => {
        if (booking.status === 'CANCELED') {
          return
        }

        booking.items.forEach((bookingItem) => {
          if (bookingItem.type !== 'ACCOMMODATION') {
            return
          }

          if (!groups[bookingItem.accommodation.group]) {
            groups[bookingItem.accommodation.group] = []
          }

          groups[bookingItem.accommodation.group].push({
            booking,
            bookingItem
          })

          newCheckOutsCount++
        })
      })


      setGroupedCheckOutsToday(groups)
      setNewCheckOutsCount(newCheckOutsCount)
    }
  }, [dataCheckOutsToday])


  return (
    <Container maxWidth={false} disableGutters>
      <Typography variant='h5'>Hello, <b>{user.name}</b>!</Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          {newCheckInsCount > 0 && (
            <Card variant='outlined'>
              <CardHeader
                avatar={<ArrowCircleRightRoundedIcon sx={{
                  color: 'green'
                }} />}
                disableTypography
                title={<Typography><b>{newCheckInsCount}</b> check-ins today</Typography>}
                sx={{
                  pb: 0
                }}
              >
              </CardHeader>
              <CardContent>

                <Grid container direction="column" spacing={2}>
                  {Object.keys(groupedCheckInsToday).map((group) => (
                    <Grid item>
                      <Typography variant='subtitle' sx={{ fontWeight: 'bold' }}>{getAccommodationGroupName(group)}</Typography>
                      <Box
                        component="ul"
                        sx={{ margin: 0, listStyleType: 'none', paddingLeft: 0 }}>
                        {groupedCheckInsToday[group].map(({ booking, bookingItem }) => (
                          <Box sx={{
                            display: 'flex'
                          }}>
                            <Box sx={{
                              marginRight: '2px',
                            }}>
                              <BookingSourceChip hideLabel size="small" source={bookingItem.source} />
                            </Box>
                            <Box component={Link}
                              to={`/bookings/edit/${booking.id}`}>
                              <small>[{booking.id}]</small> {bookingItem.accommodation.name} until {format(bookingItem.checkOut, 'dd.MM')}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {newCheckOutsCount > 0 && (
            <Card variant='outlined'>
              <CardHeader
                avatar={<ArrowCircleLeftRoundedIcon sx={{
                  color: 'red'
                }} />}
                disableTypography
                title={<Typography>
                  <b>{newCheckOutsCount}</b> check-outs today
                </Typography>}
                sx={{
                  pb: 0
                }}
              >
              </CardHeader>
              <CardContent>

                <Grid container direction="column" spacing={2}>
                  {Object.keys(groupedCheckOutsToday).map((group) => (
                    <Grid item>
                      <Typography variant='subtitle' sx={{ fontWeight: 'bold' }}>{getAccommodationGroupName(group)}</Typography>
                      <Box
                        component="ul"
                        sx={{ margin: 0, listStyleType: 'none', paddingLeft: 0 }}>
                        {groupedCheckOutsToday[group].map(({ booking, bookingItem }) => (
                          <Box sx={{
                            display: 'flex'
                          }}>
                            <Box sx={{
                              marginRight: '2px',
                            }}>
                              <BookingSourceChip hideLabel size="small" source={bookingItem.source} />
                            </Box>
                            <Box component={Link}
                              to={`/bookings/edit/${booking.id}`}>
                              <small>[{booking.id}]</small> {bookingItem.accommodation.name} from {format(bookingItem.checkIn, 'dd.MM')}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>


    </Container >
  )
  // return <NavBarButtons />
}

export default DashboardPage
