import { gql } from '@apollo/client'
import { PRICE_FIELDS_FRAGMENT } from '../prices/fragments'

export const TOUR_FIELDS_FRAGMENT = gql`
  fragment TourFields on Tour {
    id
    name
    createdAt
    updatedAt
    personMin
    personMax
  }
`

export const TOUR_FIELDS_WITH_PRICE_RELATION_FRAGMENT = gql`
  fragment TourFieldsWithPriceRelation on Tour {
    ...TourFields
    prices {
      priceToTourId
      amount
      price {
        ...PriceFields
      }
    }
  }
  ${TOUR_FIELDS_FRAGMENT}
  ${PRICE_FIELDS_FRAGMENT}
`
