import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { endOfDay, format, startOfDay } from 'date-fns'
import { Button, ButtonGroup, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import SectionCard from '../../card/section-card'

import { CREATE_PRICE } from '../../../graphql/prices/mutations'

const AddPriceForm = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [price, setPrice] = useState({
    description: '',
    type: 'NIGHT',
    currency: 'RON',
    paxFrom: 0,
    paxTo: 0,
    nightsFrom: 0,
    nightsTo: 0,
    dateFrom: new Date(),
    dateTo: new Date(),
    reservationDateFrom: new Date(),
    reservationDateTo: new Date(),
  })

  const [createPrice] = useMutation(CREATE_PRICE, {
    onCompleted({ createPrice }) {
      enqueueSnackbar(`Successfully created price ID #${createPrice.id}`, {
        variant: 'success',
      })

      navigate(`/prices/edit/${createPrice.id}/`)
    },
    onError: (error) => {
      enqueueSnackbar(`Update error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const handleSave = async () => {
    const priceInput = {
      description: price.description,
      type: price.type,
      currency: price.currency,
      paxFrom: parseInt(price.paxFrom, 10),
      paxTo: parseInt(price.paxTo, 10),
      nightsFrom: parseInt(price.nightsFrom, 10),
      nightsTo: parseInt(price.nightsTo, 10),
      dateFrom: format(price.dateFrom, 'yyyy-MM-dd HH:mm:ss'),
      dateTo: format(price.dateTo, 'yyyy-MM-dd HH:mm:ss'),
      reservationDateFrom: format(price.reservationDateFrom, 'yyyy-MM-dd HH:mm:ss'),
      reservationDateTo: format(price.reservationDateTo, 'yyyy-MM-dd HH:mm:ss'),
    }

    await createPrice({
      variables: {
        priceInput,
      },
    })
  }

  const handleBack = () => {
    navigate(`/prices/`)
  }

  const handleDescriptionChange = (e) => {
    price.description = e.target.value
    setPrice({ ...price })
  }

  const handleTypeChange = (e) => {
    price.type = e.target.value
    setPrice({ ...price })
  }

  const handleCurrencyChange = (e) => {
    price.currency = e.target.value
    setPrice({ ...price })
  }

  const handleNightsFromChange = (e) => {
    price.nightsFrom = e.target.value
    setPrice({ ...price })
  }

  const handleNightsToChange = (e) => {
    price.nightsTo = e.target.value
    setPrice({ ...price })
  }

  const handlePaxFromChange = (e) => {
    price.paxFrom = e.target.value
    setPrice({ ...price })
  }

  const handlePaxToChange = (e) => {
    price.paxTo = e.target.value
    setPrice({ ...price })
  }

  const handleDateFromChange = (e) => {
    price.dateFrom = startOfDay(e.target.value)
    setPrice({ ...price })
  }

  const handleDateToChange = (e) => {
    price.dateTo = endOfDay(e.target.value)
    setPrice({ ...price })
  }

  const handleReservationDateFromChange = (e) => {
    price.reservationDateFrom = startOfDay(e.target.value)
    setPrice({ ...price })
  }

  const handleReservationDateToChange = (e) => {
    price.reservationDateTo = endOfDay(e.target.value)
    setPrice({ ...price })
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item>
          <ButtonGroup
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            <Button aria-label='return-back' onClick={handleBack}>
              <ChevronLeftRoundedIcon />
            </Button>
            <Button onClick={handleSave}>
              <SaveRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid
        container
        justifyContent='space-between'
        spacing={2}
        direction='row'
      >
        <Grid item xs={12} md={6}>
          <SectionCard title='Basic Info' variant='outlined'>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Description'
                  multiline
                  minRows={2}
                  defaultValue={price.description}
                  fullWidth
                  onChange={handleDescriptionChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  size='small'
                  variant='outlined'
                  fullWidth
                >
                  <InputLabel id='select-type-label'>Type</InputLabel>
                  <Select
                    labelId='select-type-label'
                    id='select-type'
                    value={price.type}
                    onChange={handleTypeChange}
                    label='Type'
                  >
                    <MenuItem value={'PAX'}>Pax</MenuItem>
                    <MenuItem value={'NIGHT'}>Night</MenuItem>
                    <MenuItem disabled value={'DISTANCE'}>
                      Distance
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  size='small'
                  variant='outlined'
                  fullWidth
                >
                  <InputLabel id='select-currency-label'>Currency</InputLabel>
                  <Select
                    labelId='select-currency-label'
                    id='select-currency'
                    value={price.currency}
                    onChange={handleCurrencyChange}
                    label='Currency'
                  >
                    <MenuItem value={'EUR'}>EUR</MenuItem>
                    <MenuItem value={'RON'}>RON</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Nights Min.'
                  defaultValue={price.nightsFrom}
                  type='number'
                  inputProps={{ min: 1 }}
                  fullWidth
                  onChange={handleNightsFromChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Nights Max.'
                  defaultValue={price.nightsTo}
                  type='number'
                  inputProps={{ min: 1 }}
                  fullWidth
                  onChange={handleNightsToChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Pax Min.'
                  defaultValue={price.paxFrom}
                  type='number'
                  inputProps={{ min: 1 }}
                  fullWidth
                  onChange={handlePaxFromChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Pax Max.'
                  defaultValue={price.paxTo}
                  type='number'
                  inputProps={{ min: 1 }}
                  fullWidth
                  onChange={handlePaxToChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Booking Date From'
                  defaultValue={format(price.dateFrom, 'yyyy-MM-dd')}
                  type='date'
                  fullWidth
                  onChange={handleDateFromChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Booking Date To'
                  defaultValue={format(price.dateTo, 'yyyy-MM-dd')}
                  type='date'
                  fullWidth
                  onChange={handleDateToChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Reservation Date From'
                  defaultValue={format(price.reservationDateFrom, 'yyyy-MM-dd')}
                  type='date'
                  fullWidth
                  onChange={handleReservationDateFromChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Reservation Date To'
                  defaultValue={format(price.reservationDateTo, 'yyyy-MM-dd')}
                  type='date'
                  fullWidth
                  onChange={handleReservationDateToChange}
                />
              </Grid>
            </Grid>
          </SectionCard>
        </Grid>
      </Grid>
    </>
  )
}

export default AddPriceForm
