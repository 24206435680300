import { gql } from '@apollo/client'

import { BOOKING_FIELDS_FRAGMENT } from './fragments'

export const CREATE_BOOKING = gql`
  mutation CreateBooking($bookingInput: CreateBookingInput!) {
    createBooking(createBookingData: $bookingInput) {
      ...BookingFields
    }
  }
  ${BOOKING_FIELDS_FRAGMENT}
`

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking($bookingInput: UpdateBookingInput!) {
    updateBooking(updateBookingData: $bookingInput) {
      ...BookingFields
    }
  }
  ${BOOKING_FIELDS_FRAGMENT}
`

export const IMPORT_ACCOMMODATION_BOOKINGS = gql`
  mutation ImportAccommodationBookings($accommodationId: Int!) {
    importAccommodationBookings(accommodationId: $accommodationId)
  }
`

export const SEND_BOOKING_CONFIRMATION_EMAIL = gql`
  mutation SendBookingConfirmationEmail($id: Int!) {
    sendBookingConfirmationEmail(id: $id)
  }
`
