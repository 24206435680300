import React, { useState } from 'react'
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { BottomScrollListener } from 'react-bottom-scroll-listener'


function EnhancedTableHead(props) {
  const {
    cells,
    onSort,
    sortBy,
    sortDirection
  } = props

  const [currentSortBy, setCurrentSortBy] = useState(sortBy)
  const [currentSortDirection, setCurrentSortDirection] = useState(sortDirection)

  const handleSort = (newSortBy) => {
    setCurrentSortBy(newSortBy)
    setCurrentSortDirection(currentSortDirection === 'asc' ? 'desc' : 'asc')

    onSort(newSortBy, currentSortDirection === 'asc' ? 'desc' : 'asc')
  }

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => (
          <TableCell
            key={cell.id}
            align={cell.align || 'left'}
            padding={cell.disablePadding ? 'none' : 'normal'}

          >
            {cell.enableSorting ? (
              <TableSortLabel
                direction={currentSortBy === cell.id ? currentSortDirection : 'asc'}
                active={currentSortBy === cell.id}
                onClick={() => handleSort(cell.id)}
              >
                {cell.label}
              </TableSortLabel>
            ) : (
              cell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTable = (props) => {
  const {
    cells,
    rows,
    onRowClick,
    onSort,
    onLoadMore,
    sortBy,
    sortDirection,
    loading = false
  } = props

  return (
    <Box>
      <Paper
        sx={{
          width: '100%',
          marginBottom: 2,
        }}
        variant='outlined'>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              cells={cells}
              onSort={onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
            <TableBody>
              <BottomScrollListener onBottom={onLoadMore}>
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.id.sortValue}
                    >
                      {cells.map((cell) => {
                        if (cell.id === 'id') {
                          return (
                            <TableCell
                              onClick={(event) =>
                                onRowClick(event, row.id.sortValue)
                              }
                              key={`${row.id.sortValue}-${cell.id}`}
                              component='th'
                              id={labelId}
                              scope='row'
                            >
                              {row.id.displayValue}
                            </TableCell>
                          )
                        }

                        return (
                          <TableCell
                            onClick={(event) =>
                              onRowClick(event, row.id.sortValue)
                            }
                            key={`${row.id.sortValue}-${cell.id}`}
                            align='left'
                          >
                            {row[cell.id].displayValue}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </BottomScrollListener>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'center', visibility: loading ? 'visible' : 'hidden' }}>
        <CircularProgress />
      </Box>
    </Box>
  )
}


export default EnhancedTable
