import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import AddBookingForm from '../../components/forms/bookings/add-booking-form'
import { useSettings } from '../../providers/settings-context-provider'
import { useSearchParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_ACCOMMODATION_AND_PRICES } from '../../graphql/accommodations/queries'

const AddBookingPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Add Booking'), [setTitle])

  const [booking, setBooking] = useState({
    status: 'NEW',
    source: 'OTHER',
    notes: '',
    customer: {
      id: null,
    },
    items: [],
  })
  const [searchParams] = useSearchParams();

  const [getAccommodation, { data: dataAccommodation }] = useLazyQuery(GET_ACCOMMODATION_AND_PRICES, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(searchParams.get('accommodationId'), 10) },
  })

  useEffect(() => {
    if (searchParams.get('accommodationId')) {
      getAccommodation()
    }
    // eslint-disable-next-line
  }, [searchParams.get('accommodationId')])

  useEffect(() => {
    if (dataAccommodation?.accommodation) {
      booking.items.push({
        type: 'ACCOMMODATION',
        source: 'OTHER',
        accommodation: dataAccommodation?.accommodation,
        checkIn: searchParams.get('checkIn'),
        checkOut: searchParams.get('checkOut'),
      })

      setBooking({ ...booking })
    }
    // eslint-disable-next-line
  }, [dataAccommodation])


  return (
    <Container maxWidth={false} disableGutters>
      <AddBookingForm booking={booking} />
    </Container>
  )
}

export default AddBookingPage
