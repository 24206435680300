import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Container } from '@mui/material'
import { PageLoader } from '../../components/page-loader'
import EditTourForm from '../../components/forms/tours/edit-tour-form'

import { GET_TOUR_AND_PRICES } from '../../graphql/tours/queries'
import { useSettings } from '../../providers/settings-context-provider'

const EditTourPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Edit Tour'), [setTitle])

  const { id } = useParams()

  const { loading, error, data, refetch } = useQuery(GET_TOUR_AND_PRICES, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(id, 10) },
  })

  if (loading) return <PageLoader />
  if (error) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <EditTourForm
        tour={data.tour}
        prices={data.prices}
        refetchTour={refetch}
      />
    </Container>
  )
}

export default EditTourPage
