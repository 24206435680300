import { gql } from '@apollo/client'
import { PRICE_FIELDS_FRAGMENT } from './fragments'

export const CREATE_PRICE = gql`
  mutation CreatePrice($priceInput: CreatePriceInput!) {
    createPrice(createPriceData: $priceInput) {
      ...PriceFields
    }
  }
  ${PRICE_FIELDS_FRAGMENT}
`

export const UPDATE_PRICE = gql`
  mutation UpdatePrice($priceInput: UpdatePriceInput!) {
    updatePrice(updatePriceData: $priceInput) {
      ...PriceFields
    }
  }
  ${PRICE_FIELDS_FRAGMENT}
`

export const DELETE_PRICE = gql`
  mutation DeletePrice($id: Int!) {
    deletePrice(id: $id)
  }
`
