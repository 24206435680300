import { gql } from '@apollo/client'
import { PRICE_FIELDS_FRAGMENT } from './fragments'

export const GET_PRICES = gql`
  query GetPrices(
    $skip: Int
    $take: Int
    $orderBy: PricesOrderBy!
    $orderDirection: PricesOrderDirection!
  ) {
    prices(skip: $skip, take: $take, orderBy: $orderBy, orderDirection: $orderDirection) {
      ...PriceFields
    }
  }
  ${PRICE_FIELDS_FRAGMENT}
`

export const GET_PRICE = gql`
  query GetPrice($id: Int!) {
    price(id: $id) {
      ...PriceFields
    }
  }
  ${PRICE_FIELDS_FRAGMENT}
`
