import { gql } from '@apollo/client'
import {
  TOUR_FIELDS_FRAGMENT,
  TOUR_FIELDS_WITH_PRICE_RELATION_FRAGMENT,
} from './fragments'
import { PRICE_FIELDS_FRAGMENT } from '../prices/fragments'

export const GET_TOURS = gql`
  query GetTours(
    $skip: Int
    $take: Int
    $orderBy: ToursOrderBy!
    $orderDirection: ToursOrderDirection!
  ) {
    tours(skip: $skip, take: $take, orderBy: $orderBy, orderDirection: $orderDirection) {
      ...TourFields
    }
  }
  ${TOUR_FIELDS_FRAGMENT}
`

export const GET_TOUR_AND_PRICES = gql`
  query GetTour($id: Int!) {
    tour(id: $id) {
      ...TourFieldsWithPriceRelation
    }
    prices {
      ...PriceFields
    }
  }
  ${TOUR_FIELDS_WITH_PRICE_RELATION_FRAGMENT}
  ${PRICE_FIELDS_FRAGMENT}
`
