import React, { useState } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Button, ButtonGroup, Divider, Grid, TextField, Typography } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { DELETE_TOUR, UPDATE_TOUR } from '../../../graphql/tours/mutations'

import { useConfirmation } from '../../../providers/confirmation-service-provider'
import { lightFormat } from 'date-fns'
import SectionCard from '../../card/section-card'
import PriceList from '../../price/price-list'

const EditTourForm = ({ tour: inputTour, prices, refetchTour }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()

  const [tour, setTour] = useState(_.cloneDeep(inputTour))

  const [updateTour, { loading: updating }] = useMutation(UPDATE_TOUR, {
    onCompleted({ updateTour }) {
      enqueueSnackbar(`Successfully updated tour ID #${updateTour.id}`, {
        variant: 'success',
      })
    },
    onError: (error) => {
      enqueueSnackbar(`Update error: ${error.message}`, {
        variant: 'error',
      })
    },
  })
  const [deleteTour, { loading: deleting }] = useMutation(DELETE_TOUR, {
    onCompleted() {
      enqueueSnackbar('Successfully deleted', {
        variant: 'success',
      })

      navigate('/tours/')
    },
    onError: (error) => {
      enqueueSnackbar(`Delete error: ${error.message}`, {
        variant: 'error',
      })
    },
  })


  const handleSave = async () => {
    const tourInput = {
      id: tour.id,
      name: tour.name,
      personMin: parseInt(tour.personMin, 10),
      personMax: parseInt(tour.personMax, 10),
    }

    const prices = []

    tour.prices
      .filter((p) => p.amount)
      .forEach((p) => {
        prices.push({
          priceId: p.price.id,
          amount: p.amount,
        })
      })

    if (prices) {
      tourInput.prices = prices
    }

    await updateTour({
      variables: {
        tourInput,
      },
    })

    refetchTour()
  }

  const handleDelete = async () => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to remove this tour?',
      description:
        'Removing this tour will cause problems in syncing prices and bookings',
    })
      .then(async () => {
        await deleteTour({
          variables: {
            id: tour.id,
          },
        })
      })
      .catch(() => { })
  }

  const handleBack = () => {
    navigate('/tours/')
  }

  const handleAddNewPriceMapping = (price) => {
    tour.prices.push({
      priceToAccommodationId: `new-${price.id}`,
      amount: null,
      price,
    })

    setTour({ ...tour })
  }

  const handleDeletePriceMapping = (price) => {
    const index = tour.prices.findIndex(
      (p) => p.priceToTourId === price.priceToTourId
    )

    if (index !== -1) {
      tour.prices.splice(index, 1)
    }

    setTour({ ...tour })
  }

  const handleChangePriceMapping = (price, newAmount) => {
    tour.prices.map((p) => {
      if (p.priceToTourId === price.priceToTourId) {
        p.amount = parseInt(newAmount, 10)
      }

      return p
    })

    setTour({ ...tour })
  }

  const handleChangeName = (e) => {
    tour.name = e.target.value
    setTour({ ...tour })
  }

  const handleChangePersonMin = (e) => {
    tour.personMin = e.target.value
    setTour({ ...tour })
  }

  const handleChangePersonMax = (e) => {
    tour.personMax = e.target.value
    setTour({ ...tour })
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2
        }}
      >
        <Grid item>
          <ButtonGroup
            disabled={updating || deleting}
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            <Button
              aria-label='return-back'

              onClick={handleBack}
            >
              <ChevronLeftRoundedIcon />
            </Button>
            <Button onClick={handleSave}>
              <SaveRoundedIcon />
            </Button>
            <Button onClick={handleDelete}>
              <DeleteRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid
          item
          style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component='small'
            color='textSecondary'
            sx={{
              textAlign: 'right',
              fontSize: '0.7em',
            }}
          >
            Created:{' '}
            <b>
              {lightFormat(new Date(inputTour.createdAt), 'yyyy-MM-dd HH:mm:ss')}
            </b>
          </Typography>
          <Typography
            component='small'
            color='textSecondary'
            sx={{
              textAlign: 'right',
              fontSize: '0.7em',
            }}
          >
            Updated:{' '}
            <b>
              {lightFormat(new Date(inputTour.updatedAt), 'yyyy-MM-dd HH:mm:ss')}
            </b>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid container justifyContent='space-between' spacing={2}>

        <Grid item xs={12} md={6}>
          <SectionCard title='Basic Info' variant='outlined'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  label='Name'
                  size='small'
                  disabled={updating || deleting}
                  variant='outlined'
                  fullWidth
                  defaultValue={tour.name}
                  onChange={handleChangeName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Person Min.'
                  fullWidth
                  defaultValue={tour.personMin}
                  onChange={handleChangePersonMin}
                  type='number'
                  inputProps={{ min: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  variant='outlined'
                  label='Person Max.'
                  fullWidth
                  defaultValue={tour.personMax}
                  onChange={handleChangePersonMax}
                  type='number'
                  inputProps={{ min: 1 }}
                />
              </Grid>
            </Grid>
          </SectionCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionCard title='Prices' variant='outlined'>
            <PriceList
              disabled={updating || deleting}
              mappingKey='priceToTourId'
              priceMappings={tour.prices}
              prices={prices}
              onAddNewPriceMapping={handleAddNewPriceMapping}
              onDeletePriceMapping={handleDeletePriceMapping}
              onChangePriceMapping={handleChangePriceMapping}
            />
          </SectionCard>
        </Grid>
      </Grid>
    </>
  )
}

export default EditTourForm
