import React from 'react'
import { Avatar, Chip } from '@mui/material'

import turistInTransilvaniaIcon from '../../assets/images/turist_in_transilvania_icon.png'
import whatsAppIcon from '../../assets/images/whats_app_icon.png'
import bookingIcon from '../../assets/images/booking_icon.png'
import airbnbIcon from '../../assets/images/airbnb_icon.png'
import travelminitIcon from '../../assets/images/travelminit_icon.jpg'
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

const custom = {
  chipStatusturist_in_transilvania: {
    borderColor: '#90111b',
    color: '#90111b',
  },

  chipStatusbooking_com: {
    borderColor: '#003580',
    color: '#003580',
  },
}

const BookingSourceChip = ({ source, hideLabel = false, disableBorder, ...rest }) => {
  const getSourceName = (source) => {
    if (source === 'TURIST_IN_TRANSILVANIA') {
      return 'Turist in Transilvania'
    }

    else if (source === 'WHATSAPP') {
      return 'WhatsApp'
    }

    else if (source === 'EMAIL') {
      return 'E-mail'
    }

    else if (source === 'PHONE') {
      return 'Phone'
    }

    else if (source === 'BOOKING_COM') {
      return 'Booking.com'
    }

    else if (source === 'AIRBNB') {
      return 'Airbnb.com'
    }

    else if (source === 'TRAVELMINIT') {
      return 'Travelminit.ro'
    }

    else if (source === 'OTHER') {
      return 'Other'
    }

    return source
  }

  const getAvatar = (source) => {
    if (source === 'TURIST_IN_TRANSILVANIA') {
      return (
        <Avatar
          alt='Turist in Transilvania'
          src={turistInTransilvaniaIcon}
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}
        />
      )
    }

    else if (source === 'WHATSAPP') {
      return (
        <Avatar
          alt='WhatsApp'
          src={whatsAppIcon}
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}
        />
      )
    }

    else if (source === 'EMAIL') {
      return (
        <Avatar
          alt='E-mail'
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}>
          <EmailRoundedIcon sx={{
            fontSize: '10px'
          }} />
        </Avatar>
      )
    }

    else if (source === 'PHONE') {
      return (
        <Avatar
          alt='Phone'
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}>
          <PhoneIphoneRoundedIcon sx={{
            fontSize: '10px'
          }} />
        </Avatar>
      )
    }

    else if (source === 'BOOKING_COM') {
      return (
        <Avatar
          alt='Booking.com'
          src={bookingIcon}
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}
        />
      )
    }

    else if (source === 'AIRBNB') {
      return (
        <Avatar
          alt='Airbnb.com'
          src={airbnbIcon}
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}
        />
      )
    }

    else if (source === 'TRAVELMINIT') {
      return (
        <Avatar
          alt='Travelminit.ro'
          src={travelminitIcon}
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}
        />
      )
    }

    else if (source === 'OTHER') {
      return (
        <Avatar
          alt='Other'
          sx={{
            width: 20,
            height: 20,
          }}
          {...rest}>
          <QuestionMarkRoundedIcon sx={{
            fontSize: '10px'
          }} />
        </Avatar>
      )
    }

    return <Avatar>{` `}</Avatar>
  }

  const style = {}

  if (disableBorder) {
    style.border = 'medium none'
  }

  if (hideLabel) {
    return getAvatar(source)
  }

  return (
    <Chip
      {...rest}
      style={style}
      variant='outlined'
      avatar={getAvatar(source)}
      sx={{
        ...custom[`chipStatus${source}`]
      }}
      label={getSourceName(source)}
    />
  )
}

export default BookingSourceChip
