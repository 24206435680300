import { gql } from '@apollo/client'
import { ACCOMMODATION_FIELDS_FRAGMENT } from './fragments'

export const CREATE_ACCOMMODATION = gql`
  mutation CreateAccommodation($accommodationInput: CreateAccommodationInput!) {
    createAccommodation(createAccommodationData: $accommodationInput) {
      id
    }
  }  
`

export const UPDATE_ACCOMMODATION = gql`
  mutation UpdateAccommodation($accommodationInput: UpdateAccommodationInput!) {
    updateAccommodation(updateAccommodationData: $accommodationInput) {
      ...AccommodationFields
    }
  }
  ${ACCOMMODATION_FIELDS_FRAGMENT}
`

export const DELETE_ACCOMMODATION = gql`
  mutation DeleteAccommodation($id: Int!) {
    deleteAccommodation(id: $id)
  }
`
