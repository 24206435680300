import * as React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {variant === 'danger' && (
          <>
            <Button color='primary' onClick={onSubmit}>
              Continue
            </Button>
            <Button color='primary' onClick={onClose} autoFocus>
              Cancel
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button color='primary' onClick={onSubmit}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
