import React, { useEffect } from 'react'
import { Container } from '@mui/material'

import AddCustomerForm from '../../components/forms/customers/add-customer-form'
import { useSettings } from '../../providers/settings-context-provider'

const AddCustomerPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Add Customer'), [setTitle])

  return (
    <Container maxWidth={false} disableGutters>
      <AddCustomerForm />
    </Container>
  )
}

export default AddCustomerPage
