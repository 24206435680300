import { Box, LinearProgress } from "@mui/material";
import React from "react";

export const PageLoader = (props) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 9999
            }}>
            <LinearProgress {...props} />
        </Box>
    )
};