import { Route, Routes } from 'react-router-dom';

import DashboardPage from './pages/dashboard/dashboard-page';
import CallbackPage from './pages/callback/callback-page';
import { AuthenticationGuard } from './components/authentication-guard';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from './components/page-loader';
import AccommodationsListPage from './pages/accommodations/accommodations-list-page';
import AddAccommodationPage from './pages/accommodations/add-accommodation-page';
import EditAccommodationPage from './pages/accommodations/edit-accommodation-page';
import ToursListPage from './pages/tours/tours-list-page';
import AddTourPage from './pages/tours/add-tour-page';
import EditTourPage from './pages/tours/edit-tour-page';
import PricesListPage from './pages/prices/prices-list-page';
import AddPricePage from './pages/prices/add-price-page';
import EditPricePage from './pages/prices/edit-price-page';
import CustomersListPage from './pages/customers/customers-list-page';
import AddCustomerPage from './pages/customers/add-customer-page';
import EditCustomerPage from './pages/customers/edit-customer-page';
import BookingsListPage from './pages/bookings/bookings-list-page';
import AddBookingPage from './pages/bookings/add-booking-page';
import EditBookingPage from './pages/bookings/edit-booking-page';
import CalendarPage from './pages/calendar/calendar-page';
import algoliasearch from 'algoliasearch';
import { InstantSearch } from 'react-instantsearch';
import AvailabilityPage from './pages/availability/availability-page';
import InvoicesListPage from './pages/invoices/invoices-list-page';
import EditInvoicePage from './pages/invoices/edit-invoice-page';


function App() {
  const { isLoading } = useAuth0();
  const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_CLIENT_ID, process.env.REACT_APP_ALGOLIA_API_KEY);


  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <InstantSearch searchClient={searchClient}>
      <Routes>
        <Route path="/" element={<AuthenticationGuard component={() => { }} />} />
        <Route path="/dashboard" element={<AuthenticationGuard component={DashboardPage} />} />
        <Route path="/accommodations/" element={<AuthenticationGuard component={AccommodationsListPage} />} />
        <Route path="/accommodations/add/" element={<AuthenticationGuard component={AddAccommodationPage} />} />
        <Route path="/accommodations/edit/:id/" element={<AuthenticationGuard component={EditAccommodationPage} />} />

        <Route path="/tours/" element={<AuthenticationGuard component={ToursListPage} />} />
        <Route path="/tours/add/" element={<AuthenticationGuard component={AddTourPage} />} />
        <Route path="/tours/edit/:id/" element={<AuthenticationGuard component={EditTourPage} />} />

        <Route path="/prices/" element={<AuthenticationGuard component={PricesListPage} />} />
        <Route path="/prices/add/" element={<AuthenticationGuard component={AddPricePage} />} />
        <Route path="/prices/edit/:id/" element={<AuthenticationGuard component={EditPricePage} />} />

        <Route path="/customers/" element={<AuthenticationGuard component={CustomersListPage} />} />
        <Route path="/customers/add" element={<AuthenticationGuard component={AddCustomerPage} />} />
        <Route path="/customers/edit/:id/" element={<AuthenticationGuard component={EditCustomerPage} />} />

        <Route path="/bookings/" element={<AuthenticationGuard component={BookingsListPage} />} />
        <Route path="/bookings/add/" element={<AuthenticationGuard component={AddBookingPage} />} />
        <Route path="/bookings/edit/:id/" element={<AuthenticationGuard component={EditBookingPage} />} />

        <Route path="/invoices/" element={<AuthenticationGuard component={InvoicesListPage} />} />
        <Route path="/invoices/edit/:seriesNameNumber/" element={<AuthenticationGuard component={EditInvoicePage} />} />

        <Route path="/calendar" element={<AuthenticationGuard component={CalendarPage} />} />

        <Route path="/availability" element={<AuthenticationGuard component={AvailabilityPage} />} />

        <Route path="/callback" element={<CallbackPage />} />
      </Routes>
    </InstantSearch>
  );
}

export default App;
