import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './providers/auth0-provider-with-navigate';
import AuthorizedApolloProvider from './providers/apollo-provider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './styles/theme';
import { SnackbarProvider } from 'notistack';
import ConfirmationServiceProvider from './providers/confirmation-service-provider';
import { SettingsProvider } from './providers/settings-context-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AuthorizedApolloProvider>
          <ThemeProvider theme={theme}>
            <SettingsProvider>
              <CssBaseline />
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <ConfirmationServiceProvider>
                  <App />
                </ConfirmationServiceProvider>
              </SnackbarProvider>
            </SettingsProvider>
          </ThemeProvider>
        </AuthorizedApolloProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
