import { gql } from '@apollo/client'
import { CUSTOMER_FIELDS_FRAGMENT } from './fragments'

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($customerInput: UpdateCustomerInput!) {
    updateCustomer(updateCustomerData: $customerInput) {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FIELDS_FRAGMENT}
`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($customerInput: CreateCustomerInput!) {
    createCustomer(createCustomerData: $customerInput) {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FIELDS_FRAGMENT}
`
