import React, { useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const CollapseableListItem = ({
  icon,
  label,
  path,
}) => {
  const navigate = useNavigate();
  const [matched, setMatched] = useState(false)

  let match = useMatch({
    path,
    end: false,
  })




  useEffect(() => {
    setMatched(!!match)
  }, [match])


  return (

    <ListItemButton
      selected={matched}
      onClick={() => { navigate(path) }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

export default CollapseableListItem
