import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { useSettings } from '../../providers/settings-context-provider'
import { Box, Card, CardContent, Container, Grid, List, ListItem, TextField } from '@mui/material'
import { addDays, differenceInDays, format, isBefore, isSameDay, startOfDay } from 'date-fns'
import { enqueueSnackbar } from 'notistack'
import { useQuery } from '@apollo/client'
import { GET_ACCOMMODATIONS } from '../../graphql/accommodations/queries'
import AccommodationGroupChip from '../../components/chip/accommodation-group-chip'
import { Link } from 'react-router-dom'

const AvailabilityPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Availability'), [setTitle])

  const [accommodationGroups, setAccommodationGroups] = useState([])
  const [checkIn, setCheckIn] = useState(new Date())
  const [checkOut, setCheckOut] = useState(addDays(new Date(), 1))

  const { data: dataAccommodations } = useQuery(GET_ACCOMMODATIONS, {
    variables: {
      skip: 0,
      take: 1000,
      orderBy: 'ID',
      orderDirection: 'DESC',
      checkIn,
      checkOut,
      status: 'PUBLISHED'
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (dataAccommodations?.accommodations) {
      const sortedAccommodationGroups = _.groupBy(dataAccommodations.accommodations, 'group')
      const sortOrder = [
        'ferdinand_apartments',
        'medieval_apartments_frauendorf',
        'meschen_burg_apartments',
        'wurmloch_burg_apartments',
        'transilvania_apartment'
      ]

      const orderedAccommodationGroups = _.fromPairs(
        _.sortBy(
          _.toPairs(sortedAccommodationGroups),
          ([group]) => sortOrder.indexOf(group)
        )
      )

      setAccommodationGroups(orderedAccommodationGroups)
    }
  }, [dataAccommodations])

  const onChangeCheckIn = (value) => {
    setCheckIn(value)

    if (
      isSameDay(startOfDay(checkOut), startOfDay(value)) ||
      isBefore(startOfDay(checkOut), startOfDay(value))
    ) {
      const days = differenceInDays(
        startOfDay(checkOut),
        startOfDay(checkIn)
      )

      const newCheckOut = format(addDays(value, days), 'yyyy-MM-dd')

      enqueueSnackbar(
        `Check-Out date changed to ${format(newCheckOut, 'dd.MM.yyyy')}`,
        {
          variant: 'warning',
        }
      )

      setCheckOut(newCheckOut)
    }
  }

  const onChangeCheckOut = (value) => {
    setCheckOut(value)
  }

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            size='small'
            variant='outlined'
            label='Check-In'
            type='date'
            inputProps={{
              min: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
              max: '2030-12-31',
            }}
            value={format(checkIn, 'yyyy-MM-dd')}
            fullWidth
            onChange={(e) => onChangeCheckIn(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size='small'
            variant='outlined'
            label='Check-Out'
            type='date'
            inputProps={{
              min: format(addDays(checkIn, 1), 'yyyy-MM-dd'),
              max: '2030-12-31',
            }}
            value={format(checkOut, 'yyyy-MM-dd')}
            fullWidth
            onChange={(e) => onChangeCheckOut(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            {Object.keys(accommodationGroups).map((group, index) => (
              <Grid item xs={12} md={4} key={`${group}-${index}`}>
                <Card variant='outlined'>
                  <CardContent>
                    <AccommodationGroupChip size="small" variant="filled" group={group} />
                    <List>
                      {accommodationGroups[group].map(accommodation => (
                        <ListItem key={`${group}-${accommodation.id}`}>
                          {accommodation.name} ✅&nbsp;&nbsp;<Box component={Link} sx={{ fontSize: '10px' }} to={`/bookings/add?checkIn=${format(checkIn, 'yyyy-MM-dd')}&checkOut=${format(checkOut, 'yyyy-MM-dd')}&accommodationId=${accommodation.id}`}>[Add]</Box>
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>


      </Grid>
    </Container >
  )
}

export default AvailabilityPage
