import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { format, lightFormat } from 'date-fns'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Chip, Container, Grid, Link } from '@mui/material'

import DirectionsBusRoundedIcon from '@mui/icons-material/DirectionsBusRounded';

import HotelRoundedIcon from '@mui/icons-material/HotelRounded';

import { GET_BOOKINGS } from '../../graphql/bookings/queries'

import EnhancedTable from '../../components/table/enhanced-table'
import { PageLoader } from '../../components/page-loader'
import TableHead from '../../components/table/table-head'
import BookingSourceChip from '../../components/chip/booking-source-chip'
import BookingStatusChip from '../../components/chip/booking-status-chip'
import { useSettings } from '../../providers/settings-context-provider'


const BookingsListPage = () => {
  const rowsPerPage = 10
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Bookings'), [setTitle])

  const [bookings, setBookings] = useState([])

  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const sortBy = 'ID'
  const sortDirection = 'desc'

  const { error, data, fetchMore, refetch, networkStatus } = useQuery(GET_BOOKINGS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      take: rowsPerPage,
      orderBy: sortBy.toUpperCase(),
      orderDirection: sortDirection.toUpperCase()
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (data) {
      setBookings(data.bookings)
    }
  }, [data])

  const handleRowClick = (e, id) => {
    e.stopPropagation()

    navigate(`/bookings/edit/${id}`)
  }

  const handleTourClick = (e, tourId) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/tours/edit/${tourId}`)
  }

  const handleAccommodationClick = (e, accommodationId) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/accommodations/edit/${accommodationId}`)
  }

  const handleCustomerClick = (e, customerId) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/customers/edit/${customerId}`)
  }

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchMore({
      variables: {
        skip: (page + 1) * rowsPerPage,
        take: rowsPerPage
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        bookings: [
          ...(previousResult.bookings ?? []), ...fetchMoreResult.bookings
        ]
      }),
    })
  }

  const handleSort = (newSortBy, newSortDirection) => {
    setPage(0)
    refetch({
      orderBy: newSortBy.toUpperCase(),
      orderDirection: newSortDirection.toUpperCase(),
      skip: 0,
      take: rowsPerPage,
    })
  }

  const handleFilter = (filter, value) => {
    setPage(0)
    refetch({
      [filter]: value,
      skip: 0,
      take: rowsPerPage,
    })
  }

  const handleCreate = () => {
    navigate('/bookings/add')
  }

  const renderSource = (booking) => {
    return <BookingSourceChip source={booking.source} />
  }

  const renderItems = (booking) => {
    if (!booking.items || booking.items.length <= 0) {
      return '-'
    }

    return (
      <Grid container direction='column'>
        {booking.items.map((item) => (
          <div key={item.id}>
            <Grid item>
              {item.type === 'TOUR' && (
                <Link
                  onClick={(e) => handleTourClick(e, item.tour.id)}
                  href={`/tours/edit/${item.tour.id}`}
                  color='primary'
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    color: 'inherit',

                    '&:hover': {
                      textDecoration: 'none',
                      fontWeight: 600,
                    },

                    '& *': {
                      marginRight: 2,
                    },
                  }}
                >
                  <DirectionsBusRoundedIcon color='action' />
                  {item.tour.name}
                </Link>
              )}
              {item.type === 'ACCOMMODATION' && (
                <Link
                  onClick={(e) =>
                    handleAccommodationClick(e, item.accommodation.id)
                  }
                  href={`/accommodations/edit/${item.accommodation.id}`}
                  color='primary'
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    color: 'inherit',

                    '&:hover': {
                      textDecoration: 'none',
                      fontWeight: 600,
                    },

                    '& *': {
                      marginRight: 2,
                    },
                  }}
                >
                  <HotelRoundedIcon color='action' />
                  {item.accommodation.name}
                </Link>
              )}
            </Grid>
          </div>
        ))}
      </Grid>
    )
  }

  const renderInvoiceNumber = (booking) => {
    if (booking.invoices.length <= 0) {
      return '-'
    }

    const invoice = _.cloneDeep(booking.invoices).pop()


    return (
      <Chip
        variant='outlined'
        style={{ border: 'medium none' }}
        size='small'
        label={`${invoice.oblioInvoice.seriesName}-${invoice.oblioInvoice.number}/${format(invoice.oblioInvoice.issueDate, 'dd.MM.yyyy')}`}
      />
    )

  }

  const renderCustomer = (booking) => {
    if (!booking.customer) {
      return '-'
    }

    return (
      <>
        <Link
          href={`/customers/edit/${booking.customer.id}`}
          color='primary'
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: 1,
            color: 'inherit',

            '&:hover': {
              textDecoration: 'none',
              fontWeight: 600,
            },


          }}
          onClick={(e) =>
            handleCustomerClick(e, booking.customer.id)
          }>

          {booking.customer.type === 'COMPANY' && (
            <>{booking.customer.companyName}<br /></>
          )}
          {booking.customer.firstName}{' '}
          <b>{booking.customer.lastName.toUpperCase()}</b>
        </Link>
        <Grid container direction='column'>

          <Grid item>

          </Grid>
          <Grid item>{booking.customer.phone}</Grid>
          <Grid item>{booking.customer.email}</Grid>

        </Grid></>

    )
  }

  if (networkStatus === NetworkStatus.loading) return <PageLoader />
  if (error) return <div>Error!</div>

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      enableSorting: true,
      label: 'ID',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Status',
    },
    {
      id: 'source',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Source',
    },
    {
      id: 'items',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Items',
    },
    {
      id: 'invoice',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Invoice',
    },
    {
      id: 'customer',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Customer',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Created',
    },
    {
      id: 'updatedAt',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Updated',
    },
  ]

  const rows = bookings.map((booking) => {
    return {
      data: booking,
      id: {
        sortValue: booking.id,
        displayValue: booking.id,
        value: booking.id,
      },
      status: {
        sortValue: booking.status,
        displayValue: (
          <BookingStatusChip size='small' status={booking.status} />
        ),
      },
      source: {
        sortValue: booking.items.length > 0 ? booking.items[0].source : '',
        displayValue: renderSource(booking),
      },
      items: {
        sortValue: '',
        displayValue: renderItems(booking),
      },
      invoice: {
        sortValue: '',
        displayValue: renderInvoiceNumber(booking),
      },
      customer: {
        sortValue: '',
        displayValue: renderCustomer(booking),
      },
      createdAt: {
        sortValue: booking.createdAt,
        displayValue: lightFormat(new Date(booking.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      },
      updatedAt: {
        sortValue: booking.updatedAt,
        displayValue: lightFormat(new Date(booking.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
      },
    }
  })

  const filters = [
    {
      type: 'number',
      field: 'id',
      label: 'ID',
      default: '',
    },
    {
      type: 'select',
      field: 'status',
      label: 'Status',
      default: '-',
      options: [
        {
          label: 'All',
          value: null,
        },
        {
          label: <BookingStatusChip status={'NEW'} size='small' />,
          value: 'NEW',
        },
        {
          label: <BookingStatusChip status={'APPROVED'} size='small' />,
          value: 'APPROVED',
        },
        {
          label: <BookingStatusChip status={'CANCELED'} size='small' />,
          value: 'CANCELED',
        },
      ],
    },
    {
      type: 'select',
      field: 'source',
      label: 'Source',
      default: '-',
      options: [
        {
          label: 'ALL',
          value: null,
        },
        {
          label: <BookingSourceChip source={'TURIST_IN_TRANSILVANIA'} size='small' />,
          value: 'TURIST_IN_TRANSILVANIA',
        },
        {
          label: <BookingSourceChip source={'BOOKING_COM'} size='small' />,
          value: 'BOOKING_COM',
        },
        {
          label: <BookingSourceChip source={'AIRBNB'} size='small' />,
          value: 'AIRBNB',
        },
        {
          label: <BookingSourceChip source={'TRAVELMINIT'} size='small' />,
          value: 'TRAVELMINIT',
        },
        {
          label: <BookingSourceChip source={'WHATSAPP'} size='small' />,
          value: 'WHATSAPP',
        },
        {
          label: <BookingSourceChip source={'PHONE'} size='small' />,
          value: 'PHONE',
        },
        {
          label: <BookingSourceChip source={'EMAIL'} size='small' />,
          value: 'EMAIL',
        },
        {
          label: <BookingSourceChip source={'OTHER'} size='small' />,
          value: 'OTHER',
        },
      ],
    },
    {
      type: 'select',
      field: 'hasInvoice',
      label: 'Has Invoice',
      default: '-',
      options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      type: 'date',
      field: 'checkOutAfter',
      label: 'Check-Out After',
      default: format(new Date(), 'yyyy-MM-dd')
    },
    {
      type: 'date',
      field: 'checkOutBefore',
      label: 'Check-Out Before',
      default: format(new Date(), 'yyyy-MM-dd')
    },

    {
      type: 'text',
      field: 'name',
      label: 'Customer',
      default: '',
    },
  ]

  return (
    <Container maxWidth={false} disableGutters>
      <TableHead
        filters={filters}
        handleFilter={handleFilter}
        handleCreate={handleCreate}
      />
      <EnhancedTable
        cells={headCells}
        rows={rows}
        onRowClick={handleRowClick}
        onSort={handleSort}
        onLoadMore={handleLoadMore}
        sortBy={sortBy}
        sortDirection={sortDirection}
        loading={networkStatus === NetworkStatus.fetchMore}
      />
    </Container>
  )
}

export default BookingsListPage
