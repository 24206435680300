import React from 'react'

import { useNavigate } from 'react-router-dom'
import { Chip, Divider, List, ListItemButton, ListItemText } from '@mui/material'
import { format } from 'date-fns'
import InvoiceStatusChip from '../chip/invoice-status-chip'


const InvoicePreviewList = ({ invoices }) => {
  const navigate = useNavigate()

  const handleViewInvoice = (seriesName, number) => {
    navigate(`/invoices/edit/${seriesName}-${number}`)
  }

  return (
    <List
      component='nav'
      aria-label='main mailbox folders'
    >
      {invoices.map((invoice, idx) => (
        <div key={`invoice-preview-${idx}`}>
          <ListItemButton onClick={() => handleViewInvoice(invoice.oblioInvoice.seriesName, invoice.oblioInvoice.number)}>
            <ListItemText
              primary={
                <>
                  <InvoiceStatusChip
                    disableBorder='true'
                    canceled={invoice.oblioInvoice.canceled}
                    draft={invoice.oblioInvoice.draft}
                    collected={invoice.oblioInvoice.collected}
                    stornoed={invoice.oblioInvoice.stornoed}
                    storno={invoice.oblioInvoice.storno}
                    size='small'
                  />
                  {invoice.oblioInvoice.number &&
                    invoice.oblioInvoice.seriesName &&
                    invoice.oblioInvoice.issueDate && (
                      <Chip
                        variant='outlined'
                        style={{ border: 'medium none' }}
                        size='small'
                        label={`${invoice.oblioInvoice.seriesName}-${invoice.oblioInvoice.number
                          }/${format(invoice.oblioInvoice.issueDate, 'dd.MM.yyyy')}`}
                      />
                    )}
                </>
              }
            />
          </ListItemButton>
          {idx < invoices.length - 1 && <Divider />}
        </div>
      ))}
    </List>
  )
}

export default InvoicePreviewList
