import { gql } from '@apollo/client'

export const CUSTOMER_FIELDS_FRAGMENT = gql`
  fragment CustomerFields on Customer {
    id
    type
    companyName
    companyFiscalCode
    companyBankAccount
    companyBankName
    companyVatNumber
    firstName
    lastName
    idCardNumber
    email
    phone
    address
    country
    notes
    createdAt
    updatedAt
  }
`
