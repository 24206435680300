import { Avatar, Chip } from '@mui/material'
import { deepOrange, green, grey, red } from '@mui/material/colors'
import React from 'react'

const custom = {
  avatarStatusDraft: {
    color: 'white',
    backgroundColor: grey[500],
  },
  chipStatusDraft: {
    borderColor: grey[500],
    color: grey[500],
  },

  avatarStatusUnpaid: {
    color: 'white',
    backgroundColor: deepOrange[500],
  },
  chipStatusUnpaid: {
    borderColor: deepOrange[500],
    color: deepOrange[500],
  },

  avatarStatusCollected: {
    color: 'white',
    backgroundColor: green[500],
  },
  chipStatusCollected: {
    borderColor: green[500],
    color: green[500],
  },

  avatarStatusCanceled: {
    color: 'white',
    backgroundColor: grey[500],
  },
  chipStatusCanceled: {
    borderColor: grey[500],
    color: grey[500],
  },

  avatarStatusStornoed: {
    color: 'white',
    backgroundColor: red[800],
  },
  chipStatusStornoed: {
    borderColor: red[800],
    color: red[800],
  },
  avatarStatusStorno: {
    color: 'white',
    backgroundColor: red[800],
  },
  chipStatusStorno: {
    borderColor: red[800],
    color: red[800],
  },
}

const InvoiceStatusChip = ({ draft, canceled, collected, stornoed, storno, disableBorder, ...rest }) => {
  const labelStatusMap = {
    Unpaid: 'Unpaid',
    Canceled: 'Canceled',
    Collected: 'Paid',
    Stornoed: 'Stornoed',
    Draft: 'Draft',
    Storno: 'Storno'
  }

  let status = 'Unpaid'

  if (draft) {
    status = 'Draft'
  } else if (canceled) {
    status = 'Canceled'
  } else if (collected) {
    status = 'Collected'
  } else if (stornoed) {
    status = 'Stornoed'
  } else if (storno) {
    status = 'Storno'
  }

  const style = {}

  if (disableBorder) {
    style.border = 'medium none'
  }

  return (
    <Chip
      {...rest}
      style={style}
      variant='outlined'
      avatar={
        <Avatar
          sx={{
            ...custom[`avatarStatus${status}`]
          }}>{` `}</Avatar>
      }
      sx={{
        ...custom[`chipStatus${status}`]
      }}
      label={labelStatusMap[status]}
    />
  )
}

export default InvoiceStatusChip
