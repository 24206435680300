import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { PageLoader } from '../../components/page-loader'
import { GET_BOOKING } from '../../graphql/bookings/queries'
import EditBookingForm from '../../components/forms/bookings/edit-booking-form'
import { useSettings } from '../../providers/settings-context-provider'

const EditBookingPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Edit Booking'), [setTitle])

  const { id } = useParams()

  const { loading, error, data, refetch } = useQuery(GET_BOOKING, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(id, 10) },
  })

  if (loading) return <PageLoader />
  if (error) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <EditBookingForm
        booking={data.booking}
        refetchBooking={refetch}
      />
    </Container>
  )
}

export default EditBookingPage
