import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'

import { PageLoader } from '../../components/page-loader'

import { GET_CUSTOMER } from '../../graphql/customers/queries'
import EditCustomerForm from '../../components/forms/customers/edit-customer-form'
import { useSettings } from '../../providers/settings-context-provider'

const EditCustomerPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Edit Customer'), [setTitle])
  const { id } = useParams()

  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(id, 10) },
  })

  if (loading) return <PageLoader />
  if (error) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <EditCustomerForm
        customer={data.customer}
        refetchCustomer={refetch}
      />
    </Container>
  )
}

export default EditCustomerPage
