import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import AddPriceForm from '../../components/forms/prices/add-price-form'
import { useSettings } from '../../providers/settings-context-provider'

const AddPricePage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Add Price'), [setTitle])

  return (
    <Container maxWidth={false} disableGutters>
      <AddPriceForm />
    </Container>
  )
}

export default AddPricePage
