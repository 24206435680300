import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { lightFormat } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Button, ButtonGroup, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CloudSyncRoundedIcon from '@mui/icons-material/CloudSyncRounded';

import { UPDATE_ACCOMMODATION } from '../../../graphql/accommodations/mutations'
import { DELETE_ACCOMMODATION } from '../../../graphql/accommodations/mutations'
import { IMPORT_ACCOMMODATION_BOOKINGS } from '../../../graphql/bookings/mutations'

import { useConfirmation } from '../../../providers/confirmation-service-provider'
import SectionCard from '../../card/section-card'
import AccommodationGroupChip from '../../chip/accommodation-group-chip'
import PriceList from '../../price/price-list'
import AccommodationStatusChip from '../../chip/accommodation-status-chip'

const EditAccommodationForm = ({ accommodation: inputAccommodation, prices, refetchAccommodation }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()

  const [accommodation, setAccommodation] = useState(_.cloneDeep(inputAccommodation))

  useEffect(() => {
    setAccommodation(_.cloneDeep(inputAccommodation))
  }, [inputAccommodation])

  const [updateAccommodation, { loading: updating }] = useMutation(UPDATE_ACCOMMODATION, {
    onCompleted({ updateAccommodation }) {
      enqueueSnackbar(`Successfully updated accommodation ID ${updateAccommodation.id}`, {
        variant: 'success',
      })
    },
    onError: (error) => {
      enqueueSnackbar(`Update error: ${error.message}`, {
        variant: 'error',
      })
    },
  })
  const [deleteAccommodation, { loading: deleting }] = useMutation(DELETE_ACCOMMODATION, {
    onCompleted(data) {
      enqueueSnackbar('Successfully deleted', {
        variant: 'success',
      })

      navigate('/accommodations/')
    },
    onError: (error) => {
      enqueueSnackbar(`Delete error: ${error.message}`, {
        variant: 'error',
      })
    },
  })
  const [importAccommodationBookings, { loading: importing }] = useMutation(IMPORT_ACCOMMODATION_BOOKINGS, {
    onCompleted(data) {
      enqueueSnackbar('Successfully imported bookings.', {
        variant: 'success',
      })
    },
    onError: (error) => {
      enqueueSnackbar(`Import error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const handleSave = async () => {
    const accommodationInput = {
      id: accommodation.id,
      status: accommodation.status,
      name: accommodation.name,
      group: accommodation.group,
      bookingComCalendarUrl: accommodation.bookingComCalendarUrl,
      airbnbCalendarUrl: accommodation.airbnbCalendarUrl,
      travelminitCalendarUrl: accommodation.travelminitCalendarUrl,
    }

    const prices = []

    accommodation.prices
      .filter((p) => p.amount)
      .forEach((p) => {
        prices.push({
          priceId: p.price.id,
          amount: p.amount,
        })
      })

    if (prices) {
      accommodationInput.prices = prices
    }

    await updateAccommodation({
      variables: {
        accommodationInput,
      },
    })

    refetchAccommodation()
  }

  const handleDelete = async () => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to remove this accommodation?',
      description:
        'Removing this accommodation will cause problems in syncing prices and bookings',
    })
      .then(async () => {
        await deleteAccommodation({
          variables: {
            id: accommodation.id,
          },
        })
      })
      .catch(() => { })
  }

  const handleImportBookings = async () => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to sync bookings for this accommodation?',
      description:
        'New bookings from booking.com will be imported. Existing bookings will be updated, and bookings that have been canceled will be canceled also here.',
    })
      .then(async () => {
        await importAccommodationBookings({
          variables: {
            accommodationId: accommodation.id,
          },
        })
      })
      .catch(() => { })
  }

  const handleBack = () => {
    navigate('/accommodations/')
  }

  const handleStatusChange = (e) => {
    accommodation.status = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleAddNewPriceMapping = (price) => {
    accommodation.prices.push({
      priceToAccommodationId: `new-${price.id}`,
      amount: null,
      price,
    })

    setAccommodation({ ...accommodation })
  }

  const handleDeletePriceMapping = (price) => {
    const index = accommodation.prices.findIndex(
      (p) => p.priceToAccommodationId === price.priceToAccommodationId
    )

    if (index !== -1) {
      accommodation.prices.splice(index, 1)
    }

    setAccommodation({ ...accommodation })
  }

  const handleChangePriceMapping = (price, newAmount) => {
    accommodation.prices.map((p) => {
      if (p.priceToAccommodationId === price.priceToAccommodationId) {
        p.amount = parseInt(newAmount, 10)
      }

      return p
    })

    setAccommodation({ ...accommodation })
  }

  const handleChangeName = (e) => {
    accommodation.name = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeGroup = (e) => {
    accommodation.group = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeBookingComCalendarUrl = (e) => {
    accommodation.bookingComCalendarUrl = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeAirbnbCalendarUrl = (e) => {
    accommodation.airbnbCalendarUrl = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeTravelminitCalendarUrl = (e) => {
    accommodation.travelminitCalendarUrl = e.target.value
    setAccommodation({ ...accommodation })
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2
        }}
      >
        <Grid item>
          <ButtonGroup
            disabled={updating || deleting || importing}
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            <Button
              aria-label='return-back'

              onClick={handleBack}
            >
              <ChevronLeftRoundedIcon />
            </Button>
            <Button onClick={handleSave}>
              <SaveRoundedIcon />
            </Button>
            <Button onClick={handleDelete}>
              <DeleteRoundedIcon />
            </Button>
            <Button onClick={handleImportBookings}>
              <CloudSyncRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid
          item
          style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component='small'
            color='textSecondary'
            sx={{
              textAlign: 'right',
              fontSize: '0.7em',
            }}
          >
            Created:{' '}
            <b>
              {lightFormat(new Date(inputAccommodation.createdAt), 'yyyy-MM-dd HH:mm:ss')}
            </b>
          </Typography>
          <Typography
            component='small'
            color='textSecondary'
            sx={{
              textAlign: 'right',
              fontSize: '0.7em',
            }}
          >
            Updated:{' '}
            <b>
              {lightFormat(new Date(inputAccommodation.updatedAt), 'yyyy-MM-dd HH:mm:ss')}
            </b>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item xs={12} md={6}>
          <SectionCard title='Basic Info' variant='outlined'>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  label='Name'
                  size='small'
                  disabled={updating || deleting || importing}
                  variant='outlined'
                  fullWidth
                  defaultValue={accommodation.name}
                  onChange={handleChangeName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size='small'
                  variant='outlined'
                  fullWidth
                >
                  <InputLabel id='select-type-label'>Status</InputLabel>
                  <Select
                    labelId='select-type-label'
                    id='select-type'
                    value={accommodation.status}
                    onChange={handleStatusChange}
                    label='Status'
                  >
                    <MenuItem value={'PUBLISHED'}>
                      <AccommodationStatusChip size='small' status='PUBLISHED' />
                    </MenuItem>
                    <MenuItem value={'UNPUBLISHED'}>
                      <AccommodationStatusChip size='small' status='UNPUBLISHED' />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size='small'
                  variant='outlined'
                  fullWidth
                  disabled={updating || deleting || importing}
                >
                  <InputLabel id='group'>Group</InputLabel>
                  <Select
                    labelId='group'
                    id='select-group'
                    value={accommodation.group || 'ungrouped'}
                    onChange={handleChangeGroup}
                    label='Group'
                  >
                    <MenuItem value={'ungrouped'}>-</MenuItem>
                    <MenuItem value={'ferdinand_apartments'}>
                      <AccommodationGroupChip
                        size='small'
                        group='ferdinand_apartments'
                      />
                    </MenuItem>
                    <MenuItem value={'transilvania_apartment'}>
                      <AccommodationGroupChip
                        size='small'
                        group='transilvania_apartment'
                      />
                    </MenuItem>
                    <MenuItem value={'medieval_apartments_frauendorf'}>
                      <AccommodationGroupChip
                        size='small'
                        group='medieval_apartments_frauendorf'
                      />
                    </MenuItem>
                    <MenuItem value={'meschen_burg_apartments'}>
                      <AccommodationGroupChip
                        size='small'
                        group='meschen_burg_apartments'
                      />
                    </MenuItem>
                    <MenuItem value={'wurmloch_burg_apartments'}>
                      <AccommodationGroupChip
                        size='small'
                        group='wurmloch_burg_apartments'
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  disabled={updating || deleting || importing}
                  label='Booking.com Calendar URL'
                  size='small'
                  variant='outlined'
                  fullWidth
                  multiline
                  defaultValue={accommodation.bookingComCalendarUrl}
                  onChange={handleChangeBookingComCalendarUrl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  disabled={updating || deleting || importing}
                  label='Airbnb.com Calendar URL'
                  size='small'
                  variant='outlined'
                  fullWidth
                  multiline
                  defaultValue={accommodation.airbnbCalendarUrl}
                  onChange={handleChangeAirbnbCalendarUrl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  disabled={updating || deleting || importing}
                  label='Travelminit.ro Calendar URL'
                  size='small'
                  variant='outlined'
                  fullWidth
                  multiline
                  defaultValue={accommodation.travelminitCalendarUrl}
                  onChange={handleChangeTravelminitCalendarUrl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  disabled
                  label='External Calendar URL'
                  size='small'
                  variant='outlined'
                  fullWidth
                  multiline
                  defaultValue={accommodation.calendarUrl}
                />
              </Grid>
            </Grid>
          </SectionCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='stretch'
            direction='column'
            spacing={2}
          >
            <Grid item xs={12}>
              <SectionCard title='Prices' variant='outlined'>
                <PriceList
                  disabled={updating || deleting || importing}
                  mappingKey='priceToAccommodationId'
                  priceMappings={accommodation.prices}
                  prices={prices}
                  onAddNewPriceMapping={handleAddNewPriceMapping}
                  onDeletePriceMapping={handleDeletePriceMapping}
                  onChangePriceMapping={handleChangePriceMapping}
                />
              </SectionCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default EditAccommodationForm
