import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Button, ButtonGroup, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import SectionCard from '../../card/section-card'

import { CREATE_CUSTOMER } from '../../../graphql/customers/mutations'

const AddCustomerForm = ({ onSave, dialogView }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [valid, setValid] = useState({
    firstName: false,
    lastName: false,
  })

  const [customer, setCustomer] = useState({
    type: 'INDIVIDUAL',
    companyName: '',
    companyFiscalCode: '',
    companyBankAccount: '',
    companyBankName: '',
    companyVatNumber: '',
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    address: '',
    notes: null,
    country: 'Romania',
  })

  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onCompleted({ createCustomer }) {
      enqueueSnackbar(`Successfully created customer ID #${createCustomer.id}`, {
        variant: 'success',
      })

      if (onSave) {
        onSave(createCustomer)
      } else {
        navigate(`/customers/edit/${createCustomer.id}/`)
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Update error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const handleSave = async () => {
    if (Object.keys(valid).some((k) => !valid[k])) {
      enqueueSnackbar('Some fields are missing', {
        variant: 'error',
      })

      return
    }

    const customerInput = {
      type: customer.type,
      companyName: customer.companyName,
      companyFiscalCode: customer.companyFiscalCode,
      companyBankAccount: customer.companyBankAccount,
      companyBankName: customer.companyBankName,
      companyVatNumber: customer.companyVatNumber,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phone,
      address: customer.address,
      country: customer.country,
      notes: customer.notes,
    }

    await createCustomer({
      variables: {
        customerInput,
      },
    })
  }

  const handleBack = () => {
    navigate(`/customers/`)
  }

  const handleChangeField = (value, field) => {
    if (['firstName', 'lastName'].includes(field)) {
      if (value) {
        valid[field] = true
        setValid({ ...valid })
      } else {
        valid[field] = false
        setValid({ ...valid })
      }
    }

    customer[field] = value
    setCustomer({ ...customer })
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item>
          <ButtonGroup
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            {!dialogView && <Button aria-label='return-back' onClick={handleBack}>
              <ChevronLeftRoundedIcon />
            </Button>}
            <Button onClick={handleSave}>
              <SaveRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid
        container
        justifyContent='space-between'
        spacing={2}
        direction='row'
      >
        <Grid item xs={12}>
          <SectionCard title='Basic Info' variant='outlined'>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  size='small'
                  variant='outlined'
                  fullWidth
                >
                  <InputLabel id='type-label'>Type</InputLabel>
                  <Select
                    labelId='type-label'
                    id='type'
                    value={customer.type}
                    label='Type'
                    onChange={(e) => handleChangeField(e.target.value, 'type')}
                  >
                    <MenuItem value={'INDIVIDUAL'}>Individual</MenuItem>
                    <MenuItem value={'COMPANY'}>Company</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {customer.type === 'COMPANY' && (
              <>
                <Divider sx={{
                  marginTop: 2,
                  marginBottom: 2,
                }} />
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id='company-name'
                      label='Company Name'
                      defaultValue={customer.companyName}
                      onChange={(e) =>
                        handleChangeField(e.target.value, 'companyName')
                      }
                      fullWidth
                      size='small'
                      multiline
                      variant='outlined'
                      maxRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='company-fiscal-code'
                      label='Fiscal Code'
                      defaultValue={customer.companyFiscalCode}
                      onChange={(e) =>
                        handleChangeField(e.target.value, 'companyFiscalCode')
                      }
                      fullWidth
                      size='small'
                      multiline
                      variant='outlined'
                      maxRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='company-bank-account'
                      label='Bank Account'
                      defaultValue={customer.companyBankAccount}
                      onChange={(e) =>
                        handleChangeField(e.target.value, 'companyBankAccount')
                      }
                      fullWidth
                      size='small'
                      multiline
                      variant='outlined'
                      maxRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='company-bank-name'
                      label='Bank Name'
                      defaultValue={customer.companyBankName}
                      onChange={(e) =>
                        handleChangeField(e.target.value, 'companyBankName')
                      }
                      fullWidth
                      multiline
                      size='small'
                      variant='outlined'
                      maxRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id='company-vat-number'
                      label='VAT'
                      defaultValue={customer.companyVatNumber}
                      onChange={(e) =>
                        handleChangeField(e.target.value, 'companyVatNumber')
                      }
                      fullWidth
                      size='small'
                      multiline
                      variant='outlined'
                      maxRows={4}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Divider sx={{
              marginTop: 2,
              marginBottom: 2,
            }} />
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id='first-name'
                  label='First Name'
                  defaultValue={customer.firstName}
                  onChange={(e) =>
                    handleChangeField(e.target.value, 'firstName')
                  }
                  fullWidth
                  multiline
                  error={!valid.firstName}
                  size='small'
                  variant='outlined'
                  maxRows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='last-name'
                  label='Last Name'
                  defaultValue={customer.lastName}
                  onChange={(e) =>
                    handleChangeField(e.target.value, 'lastName')
                  }
                  fullWidth
                  error={!valid.lastName}
                  multiline
                  size='small'
                  variant='outlined'
                  maxRows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='email'
                  label='Email'
                  defaultValue={customer.email}
                  onChange={(e) => handleChangeField(e.target.value, 'email')}
                  fullWidth
                  multiline
                  size='small'
                  type='email'
                  variant='outlined'
                  maxRows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='phone'
                  label='Phone'
                  defaultValue={customer.phone}
                  onChange={(e) => handleChangeField(e.target.value, 'phone')}
                  fullWidth
                  multiline
                  size='small'
                  variant='outlined'
                  maxRows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='address'
                  label='Address'
                  defaultValue={customer.address}
                  onChange={(e) => handleChangeField(e.target.value, 'address')}
                  fullWidth
                  multiline
                  size='small'
                  variant='outlined'
                  maxRows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='country'
                  label='Country'
                  defaultValue={customer.country}
                  onChange={(e) => handleChangeField(e.target.value, 'country')}
                  fullWidth
                  size='small'
                  variant='outlined'
                  maxRows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='notes'
                  label='Notes'
                  defaultValue={customer.notes}
                  onChange={(e) => handleChangeField(e.target.value, 'notes')}
                  fullWidth
                  minRows={2}
                  multiline
                  size='small'
                  variant='outlined'
                  maxRows={4}
                />
              </Grid>
            </Grid>
          </SectionCard>
        </Grid>
      </Grid>
    </>
  )
}

export default AddCustomerForm
