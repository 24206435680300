import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import EditAccommodationForm from '../../components/forms/accommodations/edit-accommodation-form'

import { GET_ACCOMMODATION_AND_PRICES } from '../../graphql/accommodations/queries'
import { Container } from '@mui/material'
import { PageLoader } from '../../components/page-loader'
import { useSettings } from '../../providers/settings-context-provider'

const EditAccommodationPage = () => {
  const { id } = useParams()
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Edit Accommodation'), [setTitle])

  const { loading, error, data, refetch } = useQuery(GET_ACCOMMODATION_AND_PRICES, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(id, 10) },
  })

  if (loading) return <PageLoader />
  if (error) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <EditAccommodationForm
        accommodation={data.accommodation}
        prices={data.prices}
        refetchAccommodation={refetch}
      />
    </Container>
  )
}

export default EditAccommodationPage
