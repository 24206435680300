import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            light: 'rgba(144, 17, 27, 0.43)',
            main: 'rgba(144, 17, 27, 1)',
            dark: 'rgba(107, 14, 22, 1)',
            contrastText: '#fff',
        },
        secondary: {
            light: 'rgba(81, 188, 230, 0.6)',
            main: 'rgba(81, 188, 230, 1)',
            dark: 'rgba(74, 170, 207, 1)',
            contrastText: '#fff',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
});

export default theme;