import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { Avatar, AvatarGroup, Button, ButtonGroup, Card, Grid, IconButton, TextField } from '@mui/material'

import DirectionsBusRoundedIcon from '@mui/icons-material/DirectionsBusRounded';
import HotelRoundedIcon from '@mui/icons-material/HotelRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';

import { GET_ACCOMMODATIONS } from '../../graphql/accommodations/queries'
import { GET_TOURS } from '../../graphql/tours/queries'
import BookingItemPrice from './booking-item-price'
import { useConfirmation } from '../../providers/confirmation-service-provider'
import ListDialog from '../dialog/list-dialog'
import { addDays, format } from 'date-fns';
import BookingSourceChip from '../chip/booking-source-chip';

const BookingItems = ({
  source,
  items,
  onAddNewItem,
  onDeleteItem,
  onItemChangeAmount,
  onItemChangeCurrency,
  onItemChangeDate,
  onItemChangePax,
  onItemChangeInvoiceDescription,
  onItemChangeNotes,
  onItemChangeCheckIn,
  onItemChangeCheckOut,
  onSyncItems,
  locked,
}) => {
  const confirm = useConfirmation()
  const [currentItems, setCurrentItems] = useState(items)
  const [openToursDialog, setOpenToursDialog] = useState(false)

  const [openAccommodationsDialog, setOpenAccommodationsDialog] =
    useState(false)

  const {
    loading: loadingAccommodations,
    error: errorAccommodations,
    data: dataAccommodations,
  } = useQuery(GET_ACCOMMODATIONS, {
    variables: {
      orderBy: 'ID',
      orderDirection: 'DESC',
      status: 'PUBLISHED'
    },
  })

  const {
    loading: loadingTours,
    error: errorTours,
    data: dataTours,
  } = useQuery(GET_TOURS, {
    variables: { orderBy: 'ID', orderDirection: 'DESC' },
  })

  useEffect(() => {
    setCurrentItems(items)
  }, [items])

  if (loadingAccommodations || loadingTours) return <></>
  if (errorAccommodations || errorTours) return <div>Error!</div>

  const handleClickOpenAccommodationsDialog = () => {
    setOpenAccommodationsDialog(true)
  }

  const handleCloseAccommodationsDialog = (item) => {
    setOpenAccommodationsDialog(false)

    if (item && item.id) {
      const bookingItem = {
        id: `${_.uniqueId('new-')}-${item.id}`,
        type: 'ACCOMMODATION',
        pax: 1,
        checkIn: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
        checkOut: format(addDays(new Date(), 3), 'yyyy-MM-dd'),
        ...(
          currentItems.filter(item => item.type === 'ACCOMMODATION').length ? {
            checkIn: currentItems.filter(item => item.type === 'ACCOMMODATION')[0].checkIn,
            checkOut: currentItems.filter(item => item.type === 'ACCOMMODATION')[0].checkOut,
          } : {}
        ),
        amount: 0,
        currency: 'RON',
        externalUid: '',
        source: source ?? 'TURIST_IN_TRANSILVANIA',
        accommodationId: item.id,
        accommodation: {
          ...item,
        },
      }

      onAddNewItem(bookingItem)
    }
  }

  const handleClickOpenToursDialog = () => {
    setOpenToursDialog(true)
  }

  const handleCloseToursDialog = (item) => {
    setOpenToursDialog(false)

    if (item && item.id) {
      const bookingItem = {
        id: `new-${item.id}`,
        type: 'TOUR',
        date: format(new Date(), 'yyyy-MM-dd'),
        pax: 3,
        amount: 0,
        currency: 'RON',
        externalUid: '',
        source: 'TURIST_IN_TRANSILVANIA',
        tourId: item.id,
        tour: {
          ...item,
        },
      }

      onAddNewItem(bookingItem)
    }
  }

  const handleOnSyncItems = (item) => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to sync dates?',
      description: `The dates on all items will be set to Check-In: ${item.checkIn}, Check-Out ${item.checkOut}`,
    })
      .then(async () => {
        onSyncItems(item)
      })
      .catch(() => { })
  }

  const handleOnDeleteItem = (item) => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to remove this item?',
      description: 'Removing this item will affect the entire booking',
    })
      .then(async () => {
        onDeleteItem(item)
      })
      .catch(() => { })
  }

  const isTour = (item) => {
    return item.type === 'TOUR'
  }

  const getTitle = (item) => {
    if (isTour(item)) {
      return item.tour.name
    }

    return item.accommodation.name
  }

  return (
    <Grid container direction='column'>
      <Grid item xs={12}>
        <ButtonGroup
          color='secondary'
          fullWidth
          aria-label='text primary button group'
        >
          <Button
            disabled={locked}
            onClick={handleClickOpenAccommodationsDialog}
          >
            Add Accommodation
          </Button>
          <Button disabled={locked} onClick={handleClickOpenToursDialog}>
            Add Tour
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {currentItems.map((item, idx) => (
            <Grid item xs={12} sm={6} key={item.id}>
              <Card
                variant='outlined'
                sx={{
                  padding: 2,
                  '&:hover': {
                    boxShadow: 3
                  }
                }}
              >

                <Grid container direction='column' spacing={2}>
                  {/* Type, source and delete*/}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid
                        item
                        xs={10}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <AvatarGroup max={4}>
                          <Avatar style={{ width: 20, height: 20, marginRight: 5 }}>
                            {item.type === 'ACCOMMODATION' && (
                              <HotelRoundedIcon style={{ fontSize: 13 }} />
                            )}
                            {item.type === 'TOUR' && (
                              <DirectionsBusRoundedIcon style={{ fontSize: 13 }} />
                            )}
                          </Avatar>
                          <BookingSourceChip hideLabel source={item.source} size='small' />
                        </AvatarGroup>
                        <b>{getTitle(item)}</b>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size='small'
                          disabled={locked}
                          onClick={() => handleOnSyncItems(item)}
                        >
                          <SyncAltRoundedIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size='small'
                          disabled={locked}
                          onClick={() => handleOnDeleteItem(item)}
                        >
                          <DeleteRoundedIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Persons and Date */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {isTour(item) && (
                        <Grid item xs={4}>
                          <TextField
                            size='small'
                            variant='outlined'
                            label='Date'
                            type='date'
                            disabled={locked}
                            defaultValue={format(item.date, 'yyyy-MM-dd')}
                            fullWidth
                            onChange={(e) => onItemChangeDate(e.target.value, item)}
                          />
                        </Grid>
                      )}

                      {/* Check-In and Check-Out */}
                      {!isTour(item) && (
                        <Grid item xs={12} sm={4}>
                          <TextField
                            size='small'
                            variant='outlined'
                            label='Check-In'
                            type='date'

                            value={format(item.checkIn, 'yyyy-MM-dd')}
                            fullWidth
                            disabled={locked}
                            onChange={(e) => onItemChangeCheckIn(e.target.value, item)}
                          />
                        </Grid>
                      )}

                      {!isTour(item) && (
                        <Grid item xs={12} sm={4}>
                          <TextField
                            size='small'
                            variant='outlined'
                            label='Check-Out'
                            type='date'
                            value={format(item.checkOut, 'yyyy-MM-dd')}
                            fullWidth
                            disabled={locked}
                            onChange={(e) => onItemChangeCheckOut(e.target.value, item)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label='Persons'
                          size='small'
                          variant='outlined'
                          type='number'
                          inputProps={{ min: 1 }}
                          defaultValue={item.pax}
                          fullWidth
                          disabled={locked}
                          onChange={(e) => onItemChangePax(e.target.value, item)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Price and estimate */}
                  <Grid item xs={12}>
                    <BookingItemPrice
                      disabled={locked}
                      onChange={onItemChangeAmount}
                      onChangeCurrency={onItemChangeCurrency}
                      item={item}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label='Invoice Product Description (optional)'
                      size='small'
                      variant='outlined'
                      type='text'
                      multiline
                      minRows={2}
                      inputProps={{ min: 1 }}
                      defaultValue={item.invoiceDescription}
                      fullWidth
                      disabled={locked}
                      onChange={(e) => onItemChangeInvoiceDescription(e.target.value, item)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label='Notes'
                      size='small'
                      variant='outlined'
                      type='text'
                      multiline
                      minRows={2}
                      inputProps={{ min: 1 }}
                      defaultValue={item.notes}
                      fullWidth
                      disabled={locked}
                      onChange={(e) => onItemChangeNotes(e.target.value, item)}
                    />
                  </Grid>

                </Grid>
              </Card>

            </Grid>
          ))}
        </Grid>
      </Grid>

      <ListDialog
        open={openAccommodationsDialog}
        onClose={handleCloseAccommodationsDialog}
        title='Select Accommodation'
        items={dataAccommodations.accommodations}
      />

      <ListDialog
        open={openToursDialog}
        onClose={handleCloseToursDialog}
        title='Select Tour'
        items={dataTours.tours}
      />
    </Grid>
  )
}

export default BookingItems
