import React, { createContext, useRef, useState } from 'react'
import ConfirmationDialog from '../components/dialog/confirmation-dialog'

const ConfirmationServiceContext = createContext(Promise.reject)

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext)

const ConfirmationServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null)

  const awaitingPromiseRef = useRef()

  const openConfirmation = (options) => {
    setConfirmationState(options)
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  )
}

export default ConfirmationServiceProvider
