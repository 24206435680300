import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Avatar, Box, Button, ButtonGroup, Chip, Divider, Grid, Typography } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';

import SectionCard from '../../card/section-card'

import { lightFormat } from 'date-fns'
import BookingSourceChip from '../../chip/booking-source-chip'
import InvoiceStatusChip from '../../chip/invoice-status-chip'
import PdfViewer from '../../document/pdf-viewer'
import { useMutation } from '@apollo/client'
import {
  CANCEL_INVOICE,
  RESTORE_INVOICE,
  DELETE_INVOICE,
  COLLECT_INVOICE,
  STORNO_INVOICE
} from '../../../graphql/invoices/mutations'
import InvoicePaymentDialog from '../../dialog/invoice-payment-dialog'
import { useConfirmation } from '../../../providers/confirmation-service-provider'

const EditInvoiceForm = ({ invoice: inputInvoice, nomenclatureSeries, refetchInvoice, dialogView }) => {
  const navigate = useNavigate()
  const confirm = useConfirmation()
  const { enqueueSnackbar } = useSnackbar()

  const isLastSeriesNumber = (nomenclatureSeries
    .find((series) => series.name === inputInvoice.seriesName)?.next - 1) === +inputInvoice.number

  const [invoice, setInvoice] = useState(_.cloneDeep(inputInvoice))
  const [openPaymentMethodDialog, setOpenPaymentMethodDialog] = useState(false)

  useEffect(() => {
    setInvoice(_.cloneDeep(inputInvoice))
  }, [inputInvoice])

  const [cancelInvoice, { loading: canceling }] = useMutation(CANCEL_INVOICE, {
    onCompleted({ cancelInvoice }) {
      enqueueSnackbar(`Successfully canceled invoice`, {
        variant: 'success',
      })

      refetchInvoice()
    },
    onError: (error) => {
      enqueueSnackbar(`Cancel invoice error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const [restoreInvoice, { loading: restoring }] = useMutation(RESTORE_INVOICE, {
    onCompleted({ restoreInvoice }) {
      enqueueSnackbar(`Successfully restored invoice`, {
        variant: 'success',
      })

      refetchInvoice()
    },
    onError: (error) => {
      enqueueSnackbar(`Restore invoice error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const [stornoInvoice, { loading: stornoing }] = useMutation(STORNO_INVOICE, {
    onCompleted({ stornoInvoice }) {
      enqueueSnackbar(`Successfully created invoice storno`, {
        variant: 'success',
      })

      refetchInvoice()
    },
    onError: (error) => {
      enqueueSnackbar(`Storno invoice error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const [deleteInvoice, { loading: deleting }] = useMutation(DELETE_INVOICE, {
    onCompleted({ deleteInvoice }) {
      enqueueSnackbar(`Successfully deleted invoice`, {
        variant: 'success',
      })

      navigate('/invoices/')
    },
    onError: (error) => {
      enqueueSnackbar(`Delete invoice error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const [collectInvoice, { loading: collecting }] = useMutation(COLLECT_INVOICE, {
    onCompleted({ collectInvoice }) {
      enqueueSnackbar(`Successfully collected invoice`, {
        variant: 'success',
      })

      refetchInvoice()
    },
    onError: (error) => {
      enqueueSnackbar(`Collect invoice error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const handleBack = () => {
    navigate(`/invoices/`)
  }

  const handleCancelInvoice = () => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to cancel this invoice?',
      description: `This invoice will be marked as "canceled". You can restore it later.`,
    })
      .then(async () => {
        cancelInvoice({
          variables: {
            seriesName: invoice.seriesName,
            number: invoice.number,
          },
        })
      })
      .catch(() => { })
  }

  const handleRestoreInvoice = () => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to restore this invoice?',
      description: `This invoice will be restored but will be marked as "unpaid".`,
    })
      .then(async () => {
        restoreInvoice({
          variables: {
            seriesName: invoice.seriesName,
            number: invoice.number,
          },
        })
      })
      .catch(() => { })
  }

  const handleStornoInvoice = () => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to create a storno for this invoice?',
      description: `This will create a new storno invoice and the current invoice will be marked as "stornoed".`,
    })
      .then(async () => {
        stornoInvoice({
          variables: {
            seriesName: invoice.seriesName,
            number: invoice.number,
            bookingId: invoice.booking.id
          },
        })
      })
      .catch(() => { })
  }

  const handleCollectInvoice = (item) => {
    setOpenPaymentMethodDialog(false)

    collectInvoice({
      variables: {
        collectInvoiceInput: {
          seriesName: invoice.seriesName,
          number: invoice.number,
          ...item
        }
      }
    })
  }

  const handleDeleteInvoice = () => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Are you sure you want to delete the invoice?',
      description: ``,
    })
      .then(async () => {
        deleteInvoice({
          variables: {
            seriesName: invoice.seriesName,
            number: invoice.number,
          },
        })
      })
      .catch(() => { })
  }

  const handleViewBooking = () => {
    navigate(`/bookings/edit/${invoice.booking.id}`)
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item>
          <ButtonGroup
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            {!dialogView && <Button
              aria-label='return-back'

              onClick={handleBack}
            >
              <ChevronLeftRoundedIcon />
            </Button>}
          </ButtonGroup>
        </Grid>
        <Grid
          item
          style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component='small'
            color='textSecondary'
            sx={{
              textAlign: 'right',
              fontSize: '0.7em',
            }}
          >
            Issued:{' '}
            <b>
              {lightFormat(new Date(inputInvoice.issueDate), 'yyyy-MM-dd HH:mm:ss')}
            </b>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid
        container
        justifyContent='space-between'
        spacing={2}
        direction='row'
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SectionCard title='Basic' variant='outlined'>
                    <Grid container direction='column' spacing={2}>
                      <Grid item xs={12}>

                        <Box sx={{ display: 'flex' }}>
                          <Box
                            component={Chip}
                            variant='outlined'
                            avatar={<Avatar>
                              <ReceiptRoundedIcon />
                            </Avatar>}
                            sx={{ marginRight: 1 }}
                            label={<b>{invoice.seriesName}-{invoice.number}</b>} />

                          {invoice?.booking && <Box
                            component={BookingSourceChip}
                            sx={{ marginRight: 1 }}
                            source={invoice.booking.source}>
                          </Box>}
                          <Box>
                            <InvoiceStatusChip
                              canceled={invoice.canceled}
                              stornoed={invoice.stornoed}
                              collected={invoice.collected}
                              draft={invoice.draft}
                              storno={invoice.storno}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </SectionCard>
                </Grid>
                <Grid item xs={12}>
                  <SectionCard title='Actions' variant='outlined'>
                    <Grid container direction='column' spacing={2}>
                      <Grid item xs={12}>
                        <ButtonGroup
                          orientation='vertical'
                          color='primary'
                          disabled={canceling || deleting || restoring || collecting || stornoing}
                          fullWidth
                          aria-label='vertical outlined primary button group'
                        >
                          <Button onClick={handleViewBooking}>
                            View Booking
                          </Button>

                          {isLastSeriesNumber && (
                            <Button onClick={handleDeleteInvoice}>
                              Delete Invoice
                            </Button>
                          )}

                          {!invoice.canceled && (
                            <Button onClick={handleCancelInvoice}>
                              Cancel Invoice
                            </Button>
                          )}

                          {invoice.canceled && (
                            <Button onClick={handleRestoreInvoice}>
                              Restore Invoice
                            </Button>
                          )}


                          {invoice.collected && invoice?.booking && (
                            <Button onClick={handleStornoInvoice}>
                              Create Storno
                            </Button>
                          )}

                          {!invoice.collected && !invoice.storno && (
                            <Button onClick={() => setOpenPaymentMethodDialog(true)}>
                              Pay Invoice
                            </Button>
                          )}
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </SectionCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
              <PdfViewer
                status={canceling || deleting || restoring || collecting || stornoing}
                url={invoice.link}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <InvoicePaymentDialog
        open={openPaymentMethodDialog}
        onClose={() => setOpenPaymentMethodDialog(false)}
        onPay={handleCollectInvoice}
        invoice={invoice}
      />
    </>
  )
}

export default EditInvoiceForm
