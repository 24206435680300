import React, { useEffect, useState } from 'react'

import { Box, Container, Grid, Tab, Tabs, Tooltip } from '@mui/material'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { useSettings } from '../../providers/settings-context-provider'
import { NetworkStatus, useQuery } from '@apollo/client'
import { GET_ACCOMMODATIONS } from '../../graphql/accommodations/queries'
import { GET_TOURS } from '../../graphql/tours/queries'
import { PageLoader } from '../../components/page-loader'
import { GET_BOOKINGS } from '../../graphql/bookings/queries'
import { addDays, format, startOfWeek } from 'date-fns'

import '../../styles/calendar.scss'

import BookingSourceChip from '../../components/chip/booking-source-chip'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CalendarPage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Calendar'), [setTitle])

  const [tabIndex, setTabIndex] = useState(0)
  const [accommodationResources, setAccommodationResources] = useState([])
  const [tourResources, setTourResources] = useState([])
  const [accommodationEvents, setAccommodationEvents] = useState([])
  const [tourEvents, setTourEvents] = useState([])

  const {
    loading: loadingAccommodations,
    error: errorAccommodations,
    data: dataAccommodations,
  } = useQuery(GET_ACCOMMODATIONS, {
    variables: {
      orderBy: 'ID',
      orderDirection: 'ASC',
      status: 'PUBLISHED'
    },
  })

  const {
    loading: loadingTours,
    error: errorTours,
    data: dataTours,
  } = useQuery(GET_TOURS, {
    variables: {
      orderBy: 'ID',
      orderDirection: 'ASC',
      status: 'PUBLISHED'
    },
  })

  useEffect(() => {
    const groupNames = {
      ferdinand_apartments: 'Ferdinand',
      medieval_apartments_frauendorf: 'Axente',
      meschen_burg_apartments: 'Mosna',
      wurmloch_burg_apartments: 'Valea V.',
      transilvania_apartment: 'Transilvania Apartment',
    }

    if (dataAccommodations?.accommodations) {
      const res = dataAccommodations.accommodations.map((accommodation) => ({
        title: accommodation.name,
        id: `accommodation_${accommodation.id}`,
        category: groupNames[accommodation.group],
      }))

      setAccommodationResources(res)
    }
  }, [dataAccommodations])


  useEffect(() => {
    if (dataTours?.tours) {
      const res = dataTours.tours.map((tour) => ({
        title: tour.name,
        id: `tour_${tour.id}`,
      }))

      setTourResources(res)
    }
  }, [dataTours])


  const { data: accommodationBookings, refetch: accommodationBookingsRefetch, networkStatus: accommodationBookingsNetworkStatus } = useQuery(GET_BOOKINGS, {
    variables: {
      skip: 0,
      take: 1000,
      orderBy: 'ID',
      orderDirection: 'DESC',
      checkOutAfter: format(new Date(), 'yyyy-MM-dd'),
      checkInBefore: format(new Date(), 'yyyy-MM-dd'),
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: tourBookings, refetch: tourBookingsRefetch, networkStatus: tourBookingsNetworkStatus } = useQuery(GET_BOOKINGS, {
    variables: {
      skip: 0,
      take: 1000,
      orderBy: 'ID',
      orderDirection: 'DESC',
      dateFrom: format(new Date(), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd'),
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (accommodationBookings?.bookings) {
      const accommodationEvents = []
      accommodationBookings.bookings.forEach((booking) => {
        if (booking.status === 'CANCELED') {
          return
        }

        booking.items.forEach((bookingItem) => {
          if (bookingItem.type !== 'ACCOMMODATION') {
            return
          }

          const event = {
            title: `${booking.id} → ${[booking.customer.firstName, booking.customer.lastName, booking.customer.companyName].join(' ')}`,
            start: bookingItem.checkIn,
            end: format(addDays(bookingItem.checkOut, 1), 'yyyy-MM-dd'),
            allDay: true,
            url: `/bookings/edit/${booking.id}`,
            resourceId: `accommodation_${bookingItem.accommodation.id}`,
            extendedProps: {
              fullName: [booking.customer.firstName, booking.customer.lastName, booking.customer.companyName].join(' '),
              checkIn: format(bookingItem.checkIn, 'dd.MM'),
              checkOut: format(bookingItem.checkOut, 'dd.MM'),
              source: bookingItem.source,
            },
          }

          accommodationEvents.push(event)
        })

      })
      setAccommodationEvents(accommodationEvents)
    }
  }, [accommodationBookings])


  useEffect(() => {
    if (tourBookings?.bookings) {
      const tourEvents = []
      tourBookings.bookings.forEach((booking) => {
        if (booking.status === 'CANCELED') {
          return
        }

        booking.items.forEach((bookingItem) => {
          if (bookingItem.type !== 'TOUR') {
            return
          }

          const event = {
            title: `${booking.id} → ${[booking.customer.firstName, booking.customer.lastName, booking.customer.companyName].join(' ')}`,
            start: bookingItem.date,
            end: format(addDays(bookingItem.date, 1), 'yyyy-MM-dd'),
            allDay: true,
            url: `/bookings/edit/${booking.id}`,
            resourceId: `tour_${bookingItem.tour.id}`,
            extendedProps: {
              fullName: [booking.customer.firstName, booking.customer.lastName, booking.customer.companyName].join(' '),
              date: format(bookingItem.date, 'dd.MM'),
              source: bookingItem.source,
            },
          }

          tourEvents.push(event)
        })

      })
      setTourEvents(tourEvents)
    }
  }, [tourBookings])

  const handleCalendarViewChange = (e, newValue) => {
    setTabIndex(newValue)
  }

  if (loadingAccommodations || loadingTours) return <PageLoader />
  if (errorAccommodations || errorTours) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <PageLoader sx={{
        display: accommodationBookingsNetworkStatus === NetworkStatus.setVariables ||
          tourBookingsNetworkStatus === NetworkStatus.setVariables ? 'block' : 'none',
        mb: 2
      }} />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleCalendarViewChange} aria-label="basic tabs example">
            <Tab label="Accommodations" {...a11yProps(0)} />
            <Tab label="Tours" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabIndex} index={0}>
          <FullCalendar
            eventClassNames="accommodation-event"
            plugins={[dayGridPlugin, resourceTimelinePlugin]}
            datesSet={(dateInfo) =>
              accommodationBookingsRefetch({
                checkOutAfter: format(startOfWeek(dateInfo.startStr), 'yyyy-MM-dd'),
                checkInBefore: format(startOfWeek(dateInfo.endStr), 'yyyy-MM-dd'),
              })
            }
            schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
            resourceAreaWidth='20vw'
            height='auto'
            timeZone='UTC'
            initialView='resourceTimeline4Weeks'
            views={{
              resourceTimeline4Weeks: {
                type: 'resourceTimeline',
                duration: { weeks: 3 },
              }
            }}
            resourceGroupField='category'
            resourceAreaHeaderContent='Accommodations'
            resources={accommodationResources}
            events={accommodationEvents}
            nowIndicator={false}
            eventContent={(innerProps) => {
              return (
                <Tooltip
                  title={
                    <Grid container direction='column'>
                      <Grid item xs={12}>
                        {innerProps.event.title}
                      </Grid>
                      <Grid item xs={12}>
                        {innerProps.event.extendedProps.fullName}
                      </Grid>
                      <Grid item xs={12}>
                        {`${innerProps.event.extendedProps.checkIn} - ${innerProps.event.extendedProps.checkOut}`}
                      </Grid>
                    </Grid>
                  }
                  arrow
                >
                  <div className='fc-event-main-frame'>
                    {innerProps.timeText && (
                      <div className='fc-event-time'>{innerProps.timeText}</div>
                    )}
                    <div className='fc-event-title-container'>
                      <div className='fc-event-title fc-sticky'>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{
                            display: 'inline-block',
                            marginRight: '2px',
                          }}>
                            <BookingSourceChip
                              hideLabel
                              source={innerProps.event.extendedProps.source}
                              sx={{ width: 15, height: 15 }} />
                          </Box>
                          {innerProps.event.title || <>&nbsp;</>}
                        </Box>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              )
            }}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <FullCalendar
            eventClassNames="tour-event"
            plugins={[dayGridPlugin, resourceTimelinePlugin]}
            datesSet={(dateInfo) =>
              tourBookingsRefetch({
                dateFrom: format(startOfWeek(dateInfo.startStr), 'yyyy-MM-dd'),
                dateTo: format(startOfWeek(dateInfo.endStr), 'yyyy-MM-dd'),
              })
            }
            schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
            resourceAreaWidth='20vw'
            height='auto'
            timeZone='UTC'
            initialView='resourceTimeline4Weeks'
            views={{
              resourceTimeline4Weeks: {
                type: 'resourceTimeline',
                duration: { weeks: 3 },
              }
            }}
            resourceAreaHeaderContent='Tours'
            resources={tourResources}
            events={tourEvents}
            nowIndicator={false}
            eventContent={(innerProps) => {
              return (
                <Tooltip
                  title={
                    <Grid container direction='column'>
                      <Grid item xs={12}>
                        {innerProps.event.title}
                      </Grid>
                      <Grid item xs={12}>
                        {innerProps.event.extendedProps.fullName}
                      </Grid>
                    </Grid>
                  }
                  arrow
                >
                  <div className='fc-event-main-frame'>
                    {innerProps.timeText && (
                      <div className='fc-event-time'>{innerProps.timeText}</div>
                    )}
                    <div className='fc-event-title-container'>
                      <div className='fc-event-title fc-sticky'>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{
                            display: 'inline-block',
                            marginRight: '2px',
                          }}>
                            <BookingSourceChip
                              hideLabel
                              source={innerProps.event.extendedProps.source}
                              sx={{ width: 15, height: 15 }} />
                          </Box>
                          {innerProps.event.title || <>&nbsp;</>}
                        </Box>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              )
            }}
          />
        </CustomTabPanel>
      </Box>
    </Container >
  )
}

export default CalendarPage
