import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'

import { PageLoader } from '../../components/page-loader'
import EditPriceForm from '../../components/forms/prices/edit-price-form'

import { GET_PRICE } from '../../graphql/prices/queries'
import { useSettings } from '../../providers/settings-context-provider'

const EditPricePage = () => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Edit Price'), [setTitle])

  const { id } = useParams()

  const { loading, error, data, refetch } = useQuery(GET_PRICE, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(id, 10) },
  })

  if (loading) return <PageLoader />
  if (error) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <EditPriceForm
        price={data.price}
        refetchPrice={refetch}
      />
    </Container>
  )
}

export default EditPricePage
