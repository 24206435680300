import { Box, Button, Card, Dialog, DialogContent, DialogTitle, Link, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import AddCustomerForm from '../forms/customers/add-customer-form';
import { Index } from 'react-instantsearch';
import Autocomplete from './autocomplete';
import EditCustomerForm from '../forms/customers/edit-customer-form';


const CustomerSelect = ({ initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);
  const [isCustomerAddDialogOpen, setIsCustomerAddDialogOpen] = useState(false)
  const [isCustomerEditDialogOpen, setIsCustomerEditDialogOpen] = useState(false)


  const onCloseCustomerAddDialog = () => {
    setIsCustomerAddDialogOpen(false)
  }

  const onCloseCustomerEditDialog = () => {
    setIsCustomerEditDialogOpen(false)
  }

  const onCreateCustomer = (customer) => {
    setIsCustomerAddDialogOpen(false)
    setValue(customer)
    onChange(customer)
  }

  const onEditCustomer = (customer) => {
    setIsCustomerEditDialogOpen(false)
    setValue(customer)
    onChange(customer)
  }

  const renderCustomerBox = (customer) => {
    return (
      <Box sx={{ p: 1 }}>
        {customer.companyName && (<><b>{customer.companyName}</b><br /></>)}
        {customer.firstName} <b>{customer.lastName}</b><br />
        {customer.phone && (<>Tel: <Link href={`tel://${customer.phone}`}>{customer.phone}</Link><br /></>)}
        {customer.email && (<>E-Mail: <Link href={`mailto://${customer.email}`}>{customer.email}</Link><br /></>)}
      </Box>
    )
  }


  return (
    <>
      <Index indexName='Customer'>
        <Autocomplete
          id='customer-id'
          fullWidth
          autoComplete
          includeInputInList
          filterSelectedOptions
          noOptionsText="No customers"
          filterOptions={(x) => x}
          getOptionLabel={(customer) => `#${customer.id} ${customer.firstName} ${customer.lastName} ${customer.companyName ? `(${customer.companyName})` : ''}`}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Select customer'}
              size='small'
              variant='outlined'
            />
          )}
          renderOption={(props, customer) => (
            <li key={`autocomplete-${customer.id}`} {...props}>
              {renderCustomerBox(customer)}
            </li>
          )} />
      </Index>

      {value && (
        <Card sx={{ p: 1, mt: 1 }}>
          {value.companyName && (<><b>{value.companyName}</b><br /></>)}
          {value.firstName}&nbsp;<b>{value.lastName}</b><br />
          {value.phone && (<>Tel: <Link href={`tel://${value.phone}`}>{value.phone}</Link><br /></>)}
          {value.email && (<>E-Mail: <Link href={`mailto://${value.email}`}>{value.email}</Link><br /></>)}
          <Link
            onClick={() => setIsCustomerEditDialogOpen(true)}
            component={RouterLink}
            sx={{
              fontSize: 10
            }}>
            Edit
          </Link>
        </Card>
      )}

      <br />
      <Button onClick={() => setIsCustomerAddDialogOpen(true)}>
        Add new customer
      </Button>

      <Dialog onClose={onCloseCustomerAddDialog} open={isCustomerAddDialogOpen}>
        <DialogTitle>Add Customer</DialogTitle>
        <DialogContent>
          <AddCustomerForm
            onSave={onCreateCustomer}
            dialogView={true} />
        </DialogContent>
      </Dialog>

      <Dialog onClose={onCloseCustomerEditDialog} open={isCustomerEditDialogOpen}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <EditCustomerForm
            onSave={onEditCustomer}
            dialogView={true}
            customer={value} />
        </DialogContent>
      </Dialog>

    </>
  )
}


export default CustomerSelect
