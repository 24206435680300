import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

import { useAuth0 } from '@auth0/auth0-react'

import React from 'react'

const AuthorizedApolloProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0()

    const authLink = setContext(async () => {
        const token = await getAccessTokenSilently()
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    })

    const apolloClient = new ApolloClient({
        link: authLink.concat(
            createUploadLink({ uri: `${process.env.REACT_APP_API_SERVER_URL}/graphql` })
        ),
        cache: new InMemoryCache(),
        connectToDevTools: true,
    })

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
