import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { NetworkStatus, useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { addDays, differenceInDays, format, isBefore, isSameDay, startOfDay } from 'date-fns'

import { Button, ButtonGroup, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';


import SectionCard from '../../card/section-card'
import BookingStatusChip from '../../chip/booking-status-chip'
import BookingSourceChip from '../../chip/booking-source-chip'
import BookingItems from '../../booking/booking-items'

import { SEND_BOOKING_CONFIRMATION_EMAIL, UPDATE_BOOKING } from '../../../graphql/bookings/mutations'
import CustomerSelect from '../../input/customer-select'
import { GET_CUSTOMERS } from '../../../graphql/customers/queries'
import InvoicePreviewList from '../../invoice/invoice-preview-list'
import { CREATE_INVOICE } from '../../../graphql/invoices/mutations'

const EditBookingForm = ({ booking: inputBooking, refetchBooking }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [valid, setValid] = useState({
    customerId: true,
  })

  const [booking, setBooking] = useState(_.cloneDeep(inputBooking))

  useEffect(() => {
    setBooking(_.cloneDeep(inputBooking))
  }, [inputBooking])

  const [updateBooking, { loading: updating }] = useMutation(UPDATE_BOOKING, {
    onCompleted({ updateBooking }) {
      enqueueSnackbar(`Successfully updated booking ID #${updateBooking.id}`, {
        variant: 'success',
      })
    },
    onError: (error) => {
      enqueueSnackbar(`Update error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const [createInvoice, { loading: creatingInvoice }] = useMutation(CREATE_INVOICE, {
    onCompleted({ createOblioInvoice }) {
      enqueueSnackbar(`Successfully created invoice ID #${createOblioInvoice.seriesName}-${createOblioInvoice.number}`, {
        variant: 'success',
      })

      refetchBooking()
    },
    onError: (error) => {
      enqueueSnackbar(`Create invoice error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const [sendBookingConfirmationEmail, { loading: sendingEmail }] = useMutation(SEND_BOOKING_CONFIRMATION_EMAIL, {
    onCompleted() {
      enqueueSnackbar(`Successfully sent booking confirmation email to ${booking.customer.email}`, {
        variant: 'success',
      })
    },
    onError: (error) => {
      enqueueSnackbar(`Error while sending booking confirmation email to customer: ${error.message}`, {
        variant: 'error',
      })
    },
  }
  )

  const { data: customersData, refetch: refetchCustomers, networkStatus } = useQuery(GET_CUSTOMERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: 0,
      take: 2,
      orderBy: 'NAME',
      orderDirection: 'ASC'
    },
    onError: () => {
      enqueueSnackbar(`Cannot fetch customers`, { variant: 'error' })
    },
  })

  const handleSave = async () => {
    if (Object.keys(valid).some((k) => !valid[k])) {
      enqueueSnackbar('Some fields are missing', {
        variant: 'error',
      })

      return
    }

    const bookingInput = {
      id: booking.id,
      status: booking.status,
      source: booking.source,
      notes: booking.notes,
      customerId: booking.customer.id,
    }

    bookingInput.items = booking.items.map((item) => {
      const bookingItem = {
        externalUid: item.externalUid,
        type: item.type,
        source: booking.source ?? item.source,
        amount: parseFloat(item.amount),
        currency: item.currency,
        pax: parseInt(item.pax, 10),
        invoiceDescription: item.invoiceDescription,
        notes: item.notes,
      }

      if (!String(item.id).startsWith('new-')) {
        bookingItem.id = item.id
      }

      if (item.type === 'ACCOMMODATION') {
        bookingItem.checkIn = item.checkIn
        bookingItem.checkOut = item.checkOut
        bookingItem.accommodationId = item.accommodation.id
      }

      if (item.type === 'TOUR') {
        bookingItem.date = item.date
        bookingItem.tourId = item.tour.id
      }

      return bookingItem
    })

    await updateBooking({
      variables: {
        bookingInput,
      },
    })

    refetchBooking()
  }

  const handleBack = () => {
    navigate(`/bookings/`)
  }

  const handleStatusChange = (e) => {
    booking.status = e.target.value
    setBooking({ ...booking })
  }

  const handleSourceChange = (e) => {
    booking.source = e.target.value
    setBooking({ ...booking })
  }

  const handleNotesChange = (e) => {
    booking.notes = e.target.value
    setBooking({ ...booking })
  }

  const handleAddNewItem = (item) => {
    booking.items.push(item)

    setBooking({ ...booking })
  }

  const handleDeleteItem = (item) => {
    booking.items = booking.items.filter((i) => i.id !== item.id)

    setBooking({ ...booking })
  }

  const handleChangeItemAmount = (amount, item) => {
    booking.items = booking.items.map((i) => ({
      ...i,
      amount: item.id === i.id ? amount : i.amount,
    }))

    setBooking({ ...booking })
  }

  const handleChangeItemCurrency = (currency, item) => {
    booking.items = booking.items.map((i) => ({
      ...i,
      currency: item.id === i.id ? currency : i.currency,
    }))

    setBooking({ ...booking })
  }

  const handleChangeItemDate = (date, item) => {
    booking.items = booking.items.map((i) => ({
      ...i,
      date: item.id === i.id ? date : i.date,
    }))

    setBooking({ ...booking })
  }

  const handleChangeItemInvoiceDescription = (invoiceDescription, item) => {
    booking.items = booking.items.map((i) => ({
      ...i,
      invoiceDescription: item.id === i.id ? invoiceDescription : i.invoiceDescription,
    }))

    setBooking({ ...booking })
  }

  const handleChangeItemNotes = (notes, item) => {
    booking.items = booking.items.map((i) => ({
      ...i,
      notes: item.id === i.id ? notes : i.notes,
    }))

    setBooking({ ...booking })
  }

  const handleChangeItemPax = (pax, item) => {
    booking.items = booking.items.map((i) => ({
      ...i,
      pax: item.id === i.id ? pax : i.pax,
    }))

    setBooking({ ...booking })
  }

  const handleChangeItemCheckIn = (checkIn, item) => {
    booking.items = booking.items.map((i) => {
      let cin = i.checkIn
      let cout = i.checkOut

      if (item.id === i.id) {
        cin = item.id === i.id ? checkIn : i.checkIn

        if (
          isSameDay(startOfDay(i.checkOut), startOfDay(cin)) ||
          isBefore(startOfDay(i.checkOut), startOfDay(cin))
        ) {
          const days = differenceInDays(
            startOfDay(i.checkOut),
            startOfDay(i.checkIn)
          )


          cout = format(addDays(cin, days), 'yyyy-MM-dd')

          enqueueSnackbar(
            `Check-Out date changed to ${format(cout, 'dd.MM.yyyy')}`,
            {
              variant: 'warning',
            }
          )
        }
      }

      return {
        ...i,
        checkIn: cin,
        checkOut: cout,
      }
    })

    setBooking({ ...booking })
  }

  const handleChangeItemCheckOut = (checkOut, item) => {
    booking.items = booking.items.map((i) => ({
      ...i,
      checkOut: item.id === i.id ? checkOut : i.checkOut,
    }))

    setBooking({ ...booking })
  }

  const handleChangeCustomerId = (customer) => {
    if (customer) {
      booking.customer = customer
      setBooking({ ...booking })

      valid.customerId = true
      setValid({ ...valid })
    } else {
      valid.customerId = false
      setValid({ ...valid })
    }
  }

  const handleCreateInvoice = async () => {
    if (!booking.customer.firstName) {
      enqueueSnackbar('Customer first name is missing', {
        variant: 'error',
      })

      return
    }

    if (!booking.customer.lastName) {
      enqueueSnackbar('Customer last name is missing', {
        variant: 'error',
      })

      return
    }

    /*
    if (!booking.customer.email && !booking.customer.phone) {
      enqueueSnackbar('Customer email/phone is missing', {
        variant: 'error',
      })

      return
    }
    */

    if (booking.items.length === 0) {
      enqueueSnackbar('Booking items are missing', {
        variant: 'error',
      })

      return
    }

    if (booking.items.some((i) => i.amount <= 0)) {
      enqueueSnackbar('Booking items have invalid amount', {
        variant: 'error',
      })

      return
    }

    const createInvoiceToBookingInput = {
      bookingId: booking.id,
    }
    await createInvoice({
      variables: {
        createInvoiceToBookingInput,
      },
    })
  }

  const handleSendConfirmationEmail = async () => {
    await sendBookingConfirmationEmail({
      variables: {
        id: booking.id,
      },
    })
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item>
          <ButtonGroup
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            <Button aria-label='return-back' onClick={handleBack}>
              <ChevronLeftRoundedIcon />
            </Button>
            <Button onClick={handleSave}>
              <SaveRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid
        container
        justifyContent='space-between'
        spacing={2}
        direction='row'
      >
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionCard title='Basic Info' variant='outlined'>
                <Grid container direction='column' spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      size='small'
                      variant='outlined'
                      fullWidth
                    >
                      <InputLabel id='select-type-label'>Status</InputLabel>
                      <Select
                        labelId='select-type-label'
                        id='select-type'
                        value={booking.status}
                        onChange={handleStatusChange}
                        label='Status'
                      >
                        <MenuItem value={'NEW'}>
                          <BookingStatusChip size='small' status='NEW' />
                        </MenuItem>
                        <MenuItem value={'APPROVED'}>
                          <BookingStatusChip size='small' status='APPROVED' />
                        </MenuItem>
                        <MenuItem value={'CANCELED'}>
                          <BookingStatusChip size='small' status='CANCELED' />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      size='small'
                      variant='outlined'
                      fullWidth
                    >
                      <InputLabel id='select-type-label'>Source</InputLabel>
                      <Select
                        labelId='select-type-label'
                        id='select-type'
                        value={booking.source}
                        onChange={handleSourceChange}
                        label='Source'
                      >
                        <MenuItem value={'TURIST_IN_TRANSILVANIA'}>
                          <BookingSourceChip size='small' source='TURIST_IN_TRANSILVANIA' />
                        </MenuItem>
                        <MenuItem value={'BOOKING_COM'}>
                          <BookingSourceChip size='small' source='BOOKING_COM' />
                        </MenuItem>
                        <MenuItem value={'AIRBNB'}>
                          <BookingSourceChip size='small' source='AIRBNB' />
                        </MenuItem>
                        <MenuItem value={'TRAVELMINIT'}>
                          <BookingSourceChip size='small' source='TRAVELMINIT' />
                        </MenuItem>
                        <MenuItem value={'WHATSAPP'}>
                          <BookingSourceChip size='small' source='WHATSAPP' />
                        </MenuItem>
                        <MenuItem value={'PHONE'}>
                          <BookingSourceChip size='small' source='PHONE' />
                        </MenuItem>
                        <MenuItem value={'EMAIL'}>
                          <BookingSourceChip size='small' source='EMAIL' />
                        </MenuItem>
                        <MenuItem value={'OTHER'}>
                          <BookingSourceChip size='small' source='OTHER' />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='booking-notes'
                      label='Notes'
                      defaultValue={booking.notes}
                      onChange={handleNotesChange}
                      fullWidth
                      multiline
                      size='small'
                      variant='outlined'
                      maxRows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent='flex-start'
                      alignItems='center'
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <CustomerSelect
                          label='Customer'
                          initialValue={booking.customer}
                          loading={networkStatus === NetworkStatus.refetch}
                          refetch={refetchCustomers}
                          options={customersData?.customers}
                          onChange={handleChangeCustomerId} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            <Grid item xs={12}>
              <SectionCard title='Invoices' variant='outlined'>
                <Grid container direction='column' spacing={2}>
                  <Grid item xs={12}>
                    <InvoicePreviewList invoices={booking.invoices} />
                    <ButtonGroup
                      color='primary'
                      aria-label='vertical outlined primary button group'
                      orientation='vertical'
                      fullWidth
                    >
                      <Button
                        disableElevation
                        disabled={updating || creatingInvoice || sendingEmail || booking.items.length <= 0}
                        onClick={() => handleCreateInvoice()}
                      >
                        Generate Invoice
                      </Button>

                    </ButtonGroup>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            <Grid item xs={12}>
              <SectionCard title='Emails' variant='outlined'>
                <Grid container direction='column' spacing={2}>
                  <Grid item xs={12}>
                    <ButtonGroup
                      color='primary'
                      aria-label='vertical outlined primary button group'
                      orientation='vertical'
                      fullWidth
                    >
                      <Button
                        disableElevation
                        disabled={updating || creatingInvoice || sendingEmail || booking.items.length <= 0 || !booking.customer.email}
                        onClick={() => handleSendConfirmationEmail()}
                      >
                        Send confirmation email
                      </Button>

                    </ButtonGroup>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <SectionCard title='Items' variant='outlined'>
            <BookingItems
              source={booking.source}
              items={booking.items}
              onAddNewItem={handleAddNewItem}
              onDeleteItem={handleDeleteItem}
              onItemChangeAmount={handleChangeItemAmount}
              onItemChangeCurrency={handleChangeItemCurrency}
              onItemChangeDate={handleChangeItemDate}
              onItemChangePax={handleChangeItemPax}
              onItemChangeCheckIn={handleChangeItemCheckIn}
              onItemChangeCheckOut={handleChangeItemCheckOut}
              onItemChangeInvoiceDescription={handleChangeItemInvoiceDescription}
              onItemChangeNotes={handleChangeItemNotes}
              locked={updating || creatingInvoice || sendingEmail}
            />
          </SectionCard>
        </Grid>
      </Grid>
    </>
  )
}

export default EditBookingForm
