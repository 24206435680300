import { gql } from '@apollo/client'
import { TOUR_FIELDS_WITH_PRICE_RELATION_FRAGMENT } from './fragments'

export const CREATE_TOUR = gql`
  mutation CreateTour($tourInput: CreateTourInput!) {
    createTour(createTourData: $tourInput) {
      ...TourFieldsWithPriceRelation
    }
  }
  ${TOUR_FIELDS_WITH_PRICE_RELATION_FRAGMENT}
`

export const UPDATE_TOUR = gql`
  mutation UpdateTour($tourInput: UpdateTourInput!) {
    updateTour(updateTourData: $tourInput) {
      ...TourFieldsWithPriceRelation
    }
  }
  ${TOUR_FIELDS_WITH_PRICE_RELATION_FRAGMENT}
`

export const DELETE_TOUR = gql`
  mutation DeleteTour($id: Int!) {
    deleteTour(id: $id)
  }
`
