import React from 'react'
import { Avatar, Chip } from '@mui/material'

const custom = {
  avatarStatusPUBLISHED: {
    color: 'white',
    backgroundColor: 'green',
  },
  chipStatusPUBLISHED: {
    borderColor: 'green',
    color: 'green',
  },

  avatarStatusUNPUBLISHED: {
    color: 'white',
    backgroundColor: 'red',
  },
  chipStatusUNPUBLISHED: {
    borderColor: 'red',
    color: 'red',
  },
}

const AccommodationStatusChip = (props) => {
  const { status } = props

  return (
    <Chip
      {...props}
      variant='outlined'
      avatar={
        <Avatar
          sx={{
            ...custom[`avatarStatus${status}`]
          }}>{` `}</Avatar>
      }
      sx={{
        ...custom[`chipStatus${status}`]
      }}
      label={status}
    />
  )
}

export default AccommodationStatusChip
