import { gql } from '@apollo/client'

export const OBLIO_INVOICE_FIELDS_FRAGMENT = gql`
  fragment OblioInvoiceFields on OblioInvoice {
      id
      seriesName
      number
      currency
      total
      issueDate
      link
      collected
      canceled
      stornoed
      storno
      draft
      booking {
        id
        source
        customer {
          id
          type
          companyName
          firstName
          lastName
          idCardNumber
          email
          phone
        }
      }
  } 
`

export const OBLIO_NOMENCLATURE_SERIES_FIELDS = gql`
  fragment OblioNomenclatureSeriesFields on OblioNomenclatureSeries {
    type
    name
    next
    start
    default
  }
`
