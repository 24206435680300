import React from 'react'

import ferdinand_apartments from '../../assets/images/ferdinand_apartments.jpg'
import medieval_apartments_frauendorf from '../../assets/images/medieval_apartments_frauendorf.jpg'
import meschen_burg_apartments from '../../assets/images/meschen_burg_apartments.jpg'
import wurmloch_burg_apartments from '../../assets/images/wurmloch_burg_apartments.jpg'
import transilvania_apartment from '../../assets/images/transilvania_apartment.jpg'
import { Avatar, Chip } from '@mui/material'

export const getAccommodationGroupName = (group) => {
  const names = {
    ferdinand_apartments: 'Ferdinand Apartments',
    medieval_apartments_frauendorf: 'Medieval Apartments Frauendorf',
    meschen_burg_apartments: 'Meschen Burg Apartments',
    wurmloch_burg_apartments: 'Wurmloch Burg Apartments',
    transilvania_apartment: 'Transilvania Apartment',
  }

  return names[group] || group || 'Unselected'
}

const getAccommodationGroupIcon = (group) => {
  const images = {
    ferdinand_apartments,
    medieval_apartments_frauendorf,
    meschen_burg_apartments,
    wurmloch_burg_apartments,
    transilvania_apartment,
  }

  if (images[group]) {
    return <Avatar src={images[group]} sx={{ width: 20, height: 20 }} />
  }

  return <Avatar sx={{ width: 20, height: 20 }} />
}

const AccommodationGroupChip = (props) => {
  const { group } = props

  return (
    <Chip
      variant='outlined'
      icon={getAccommodationGroupIcon(group)}
      label={getAccommodationGroupName(group)}
      {...props}
    />
  )
}

export default AccommodationGroupChip
