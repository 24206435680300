import { gql } from '@apollo/client'

import {
  ACCOMMODATION_FIELDS_FRAGMENT,
  ACCOMMODATION_FIELDS_WITH_PRICE_RELATION_FRAGMENT,
} from './fragments'
import { PRICE_FIELDS_FRAGMENT } from '../prices/fragments'

export const GET_ACCOMMODATIONS = gql`
  query GetAccommodations(
    $skip: Int
    $take: Int
    $orderBy: AccommodationsOrderBy!
    $orderDirection: AccommodationsOrderDirection!
    $checkIn: DateTime
    $checkOut: DateTime
    $status: AccommodationStatus
  ) {
    accommodations(skip: $skip, take: $take, orderBy: $orderBy, orderDirection: $orderDirection, checkIn: $checkIn, checkOut: $checkOut, status: $status) {
      ...AccommodationFields
    }    
  }  
  ${ACCOMMODATION_FIELDS_FRAGMENT}
`

export const GET_ACCOMMODATION_AND_PRICES = gql`
  query GetAccommodation($id: Int!) {
    accommodation(id: $id) {
      ...AccommodationFieldsWithPriceRelation
    }
    prices {
      ...PriceFields
    }
  }
  ${ACCOMMODATION_FIELDS_WITH_PRICE_RELATION_FRAGMENT}
  ${PRICE_FIELDS_FRAGMENT}
`
