import { Dialog, DialogTitle, List, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'

const ListDialog = ({ onClose, open, title, items }) => {
  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (item) => {
    onClose(item)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='simple-dialog-title'>{title}</DialogTitle>
      <List>
        {items.map((item) => (
          <ListItemButton
            onClick={() => handleListItemClick(item)}
            key={item.id}
          >
            <ListItemText primary={item.name} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  )
}

export default ListDialog
