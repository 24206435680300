import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'

import AddAccommodationForm from '../../components/forms/accommodations/add-accommodation-form'

import { GET_PRICES } from '../../graphql/prices/queries'
import { Container } from '@mui/material'
import { PageLoader } from '../../components/page-loader'
import { useSettings } from '../../providers/settings-context-provider'

const AddAccommodationPage = (props) => {
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Add Accommodation'), [setTitle])

  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: {
      skip: 0,
      take: 1000,
      orderBy: 'ID',
      orderDirection: 'ASC'
    },
  })

  if (loading) return <PageLoader />
  if (error) return <div>Error!</div>

  return (
    <Container maxWidth={false} disableGutters>
      <AddAccommodationForm prices={data.prices} />
    </Container>
  )
}

export default AddAccommodationPage
