import { gql } from '@apollo/client'

import { ACCOMMODATION_FIELDS_FRAGMENT } from '../accommodations/fragments'
import { TOUR_FIELDS_FRAGMENT } from '../tours/fragments'
import { CUSTOMER_FIELDS_FRAGMENT } from '../customers/fragments'

export const BOOKING_ITEM_FIELDS_FRAGMENT = gql`
  fragment BookingItemFields on BookingItem {
    id
    type
    externalUid
    source
    amount
    currency
    pax
    checkIn
    checkOut
    date
    createdAt
    updatedAt
    invoiceDescription
    notes
    accommodation {
      ...AccommodationFields
    }
    tour {
      ...TourFields
    }    
  }
  ${ACCOMMODATION_FIELDS_FRAGMENT}
  ${TOUR_FIELDS_FRAGMENT}
`

export const BOOKING_FIELDS_FRAGMENT = gql`
  fragment BookingFields on Booking {
    id
    notes
    status
    source
    createdAt
    updatedAt    
    customer {
      ...CustomerFields
    }
    items {
      ...BookingItemFields
    }
    invoices {
      oblioInvoice {
        id
        seriesName
        number
        issueDate
        collected
        canceled
        stornoed
        storno
        draft
      }
    }
  }
  ${BOOKING_ITEM_FIELDS_FRAGMENT}
  ${CUSTOMER_FIELDS_FRAGMENT}
`
