import React from 'react'

import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';

import { Avatar, Chip } from '@mui/material'

const CustomerChip = (props) => {
  const { label, type } = props

  const getIcon = (type) => {
    if (type === 'COMPANY') {
      return <BusinessRoundedIcon />
    }

    if (type === 'INDIVIDUAL') {
      return <PersonRoundedIcon />
    }

    return <Avatar />
  }

  return (
    <Chip {...props} variant='outlined' icon={getIcon(type)} label={label} />
  )
}

export default CustomerChip
