import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Box, Chip, Container, Grid } from '@mui/material'

import EnhancedTable from '../../components/table/enhanced-table'
import { PageLoader } from '../../components/page-loader'
import TableHead from '../../components/table/table-head'

import { useSettings } from '../../providers/settings-context-provider'
import { GET_INVOICES } from '../../graphql/invoices/queries'
import InvoiceStatusChip from '../../components/chip/invoice-status-chip'
import BookingSourceChip from '../../components/chip/booking-source-chip'


const InvoicesListPage = () => {
  const rowsPerPage = 25
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Invoices'), [setTitle])

  const [oblioInvoices, setOblioInvoices] = useState([])

  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const sortBy = 'NUMBER'
  const sortDirection = 'DESC'


  const { error, data, fetchMore, refetch, networkStatus } = useQuery(GET_INVOICES, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      take: rowsPerPage,
      orderBy: sortBy.toUpperCase(),
      orderDirection: sortDirection.toUpperCase()
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (data) {
      setOblioInvoices(data.oblioInvoices)
    }
  }, [data])

  const handleRowClick = (e, seriesNameNumber) => {
    e.stopPropagation()

    navigate(`/invoices/edit/${seriesNameNumber}`)
  }

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchMore({
      variables: {
        skip: (page + 1) * rowsPerPage,
        take: rowsPerPage
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        oblioInvoices: [
          ...previousResult.oblioInvoices, ...fetchMoreResult.oblioInvoices
        ]
      }),
    })
  }

  const handleSort = (newSortBy, newSortDirection) => {
    setPage(0)
    refetch({
      orderBy: newSortBy.toUpperCase(),
      orderDirection: newSortDirection.toUpperCase(),
      skip: 0,
      take: rowsPerPage,
    })
  }

  const handleFilter = (filter, value) => {
    refetch({
      [filter]: value
    })
  }

  const renderCustomer = (customer) => {
    if (!customer) {
      return '-'
    }

    return (
      <Grid container direction='column'>
        {customer.type === 'COMPANY' && (
          <Grid item>{customer.companyName}</Grid>
        )}
        <Grid item>
          {customer.firstName} <b>{customer.lastName.toUpperCase()}</b>
        </Grid>
        <Grid item>{customer.phone}</Grid>
        <Grid item>{customer.email}</Grid>
      </Grid>
    )
  }

  const renderInvoiceNumber = (invoice) => {
    if (invoice.seriesName && invoice.seriesName && invoice.issueDate && !invoice.draft) {
      return (
        <Chip
          variant='outlined'
          style={{ border: 'medium none' }}
          size='small'
          label={`${invoice.seriesName}-${invoice.number}/${format(
            invoice.issueDate, 'dd.MM.yyyy')}`}
        />
      )
    }

    return '-'
  }

  if (networkStatus === NetworkStatus.loading) return <PageLoader />
  if (error) return <div>Error!</div>

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      enableSorting: false,
      label: 'ID',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Status',
    },
    {
      id: 'number',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Number',
    },
    {
      id: 'source',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Source',
    },
    {
      id: 'customer',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Customer',
    },
    {
      id: 'issueDate',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Created',
    },
  ]

  const rows = oblioInvoices.map((invoice) => {
    return {
      data: invoice,
      id: {
        sortValue: `${invoice.seriesName}-${invoice.number}`,
        displayValue: invoice.id,
        value: invoice.id,
      },
      status: {
        sortValue: invoice.canceled,
        displayValue: (
          <InvoiceStatusChip
            size='small'
            canceled={invoice.canceled}
            collected={invoice.collected}
            stornoed={invoice.stornoed}
            draft={invoice.draft}
            storno={invoice.storno}
          />
        ),
      },
      number: {
        sortValue: `${invoice.invoiceSeries}-${invoice.invoiceNumber}`,
        displayValue: renderInvoiceNumber(invoice),
      },
      source: {
        sortValue: invoice?.booking?.source || invoice.id,
        displayValue: (
          invoice?.booking ? (<Box sx={{ display: 'flex' }}>
            <Box sx={{
              display: 'inline-block',
              marginRight: '2px',
            }}>
              <BookingSourceChip
                hideLabel={true}
                source={invoice?.booking?.source}
                size='small'
              />
            </Box>
            <b>#{invoice?.booking?.id}</b>
          </Box>) : '-'

        ),
      },
      customer: {
        sortValue: invoice.id,
        displayValue: renderCustomer(invoice?.booking?.customer),
      },
      issueDate: {
        sortValue: invoice.issueDate,
        displayValue: format(invoice.issueDate, 'dd.MM.yyyy'),
      },
    }
  })

  const filters = [
    {
      type: 'text',
      field: 'id',
      label: 'Id',
      default: '',
    },
    {
      type: 'text',
      field: 'seriesName',
      label: 'Series',
      default: '',
    },
    {
      type: 'text',
      field: 'number',
      label: 'Number',
      default: '',
    },
  ]

  return (
    <Container maxWidth={false} disableGutters>
      <TableHead
        filters={filters}
        handleFilter={handleFilter}
      />
      <EnhancedTable
        cells={headCells}
        rows={rows}
        onRowClick={handleRowClick}
        onSort={handleSort}
        onLoadMore={handleLoadMore}
        sortBy={sortBy}
        sortDirection={sortDirection}
        loading={networkStatus === NetworkStatus.fetchMore}
      />
    </Container>
  )
}

export default InvoicesListPage
