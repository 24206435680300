import React from 'react'

const PdfViewer = ({ url, status }) => {
  return (
    <iframe
      key={status}
      title="Invoice Viewer"
      src={url}
      width="100%"
      height="800"
      style={{ border: 0 }}
      onError={(e) => {
        // Handle iframe loading errors
        console.error('Error loading PDF:', e);
      }}
    />
  )
}

export default PdfViewer
