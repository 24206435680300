import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { Button, ButtonGroup, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import SectionCard from '../../card/section-card'
import AccommodationGroupChip from '../../chip/accommodation-group-chip';
import PriceList from '../../price/price-list'

import { CREATE_ACCOMMODATION } from '../../../graphql/accommodations/mutations'
import AccommodationStatusChip from '../../chip/accommodation-status-chip'


const AddAccommodationForm = ({ prices }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [accommodation, setAccommodation] = useState({
    status: 'UNPUBLISHED',
    name: '',
    group: '',
    prices: [],
  })

  const [createAccommodation] = useMutation(CREATE_ACCOMMODATION, {
    onCompleted({ createAccommodation }) {
      enqueueSnackbar(`Successfully created ID #${createAccommodation.id}`, {
        variant: 'success',
      })

      navigate(`/accommodations/edit/${createAccommodation.id}/`)
    },
    onError: (error) => {
      enqueueSnackbar(`Update error: ${error.message}`, {
        variant: 'error',
      })
    },
  })

  const handleSave = async () => {
    const accommodationInput = {
      status: accommodation.status,
      name: accommodation.name,
      group: accommodation.group,
      bookingComCalendarUrl: accommodation.bookingComCalendarUrl,
      airbnbCalendarUrl: accommodation.airbnbCalendarUrl,
      travelminitCalendarUrl: accommodation.travelminitCalendarUrl
    }

    const prices = []

    accommodation.prices
      .filter((p) => p.amount)
      .forEach((p) => {
        prices.push({
          priceId: p.price.id,
          amount: p.amount,
        })
      })

    if (prices) {
      accommodationInput.prices = prices
    }

    await createAccommodation({
      variables: {
        accommodationInput,
      },
    })
  }

  const handleBack = () => {
    navigate(`/accommodations/`)
  }

  const handleStatusChange = (e) => {
    accommodation.status = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleAddNewPriceMapping = (price) => {
    accommodation.prices.push({
      priceToAccommodationId: `new-${price.id}`,
      amount: null,
      price,
    })

    setAccommodation({ ...accommodation })
  }

  const handleDeletePriceMapping = (price) => {
    const index = accommodation.prices.findIndex(
      (p) => p.priceToAccommodationId === price.priceToAccommodationId
    )

    if (index !== -1) {
      accommodation.prices.splice(index, 1)
    }

    setAccommodation({ ...accommodation })
  }

  const handleChangePriceMapping = (price, newAmount) => {
    accommodation.prices.map((p) => {
      if (p.priceToAccommodationId === price.priceToAccommodationId) {
        p.amount = parseInt(newAmount, 10)
      }

      return p
    })

    setAccommodation({ ...accommodation })
  }

  const handleChangeName = (e) => {
    accommodation.name = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeGroup = (e) => {
    accommodation.group = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeBookingComCalendarUrl = (e) => {
    accommodation.bookingComCalendarUrl = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeAirbnbCalendarUrl = (e) => {
    accommodation.airbnbCalendarUrl = e.target.value
    setAccommodation({ ...accommodation })
  }

  const handleChangeTravelminitCalendarUrl = (e) => {
    accommodation.travelminitCalendarUrl = e.target.value
    setAccommodation({ ...accommodation })
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item>
          <ButtonGroup
            size='small'
            color='secondary'
            aria-label='outlined primary button group'
          >
            <Button aria-label='return-back' onClick={handleBack}>
              <ChevronLeftRoundedIcon />
            </Button>
            <Button onClick={handleSave}>
              <SaveRoundedIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid
        container
        justifyContent='space-between'
        spacing={2}
        direction='row'
      >
        <Grid item xs={12} md={6}>
          <SectionCard title='Basic Info' variant='outlined'>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  label='Name'
                  size='small'
                  variant='outlined'
                  fullWidth
                  defaultValue={accommodation.name}
                  onChange={handleChangeName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size='small'
                  variant='outlined'
                  fullWidth
                >
                  <InputLabel id='select-type-label'>Status</InputLabel>
                  <Select
                    labelId='select-type-label'
                    id='select-type'
                    value={accommodation.status}
                    onChange={handleStatusChange}
                    label='Status'
                  >
                    <MenuItem value={'PUBLISHED'}>
                      <AccommodationStatusChip size='small' status='PUBLISHED' />
                    </MenuItem>
                    <MenuItem value={'UNPUBLISHED'}>
                      <AccommodationStatusChip size='small' status='UNPUBLISHED' />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  size='small'
                  variant='outlined'
                  fullWidth
                >
                  <InputLabel id='group'>Group</InputLabel>
                  <Select
                    labelId='group'
                    id='select-group'
                    value={accommodation.group || 'ungrouped'}
                    onChange={handleChangeGroup}
                    label='Group'
                  >
                    <MenuItem value={'ungrouped'}>-</MenuItem>
                    <MenuItem value={'ferdinand_apartments'}>
                      <AccommodationGroupChip group='ferdinand_apartments' />
                    </MenuItem>
                    <MenuItem value={'transilvania_apartment'}>
                      <AccommodationGroupChip group='transilvania_apartment' />
                    </MenuItem>
                    <MenuItem value={'medieval_apartments_frauendorf'}>
                      <AccommodationGroupChip group='medieval_apartments_frauendorf' />
                    </MenuItem>
                    <MenuItem value={'meschen_burg_apartments'}>
                      <AccommodationGroupChip group='meschen_burg_apartments' />
                    </MenuItem>
                    <MenuItem value={'wurmloch_burg_apartments'}>
                      <AccommodationGroupChip group='wurmloch_burg_apartments' />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  label='Booking.com Calendar URL'
                  size='small'
                  variant='outlined'
                  fullWidth
                  multiline
                  defaultValue={accommodation.bookingComCalendarUrl}
                  onChange={handleChangeBookingComCalendarUrl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  label='Airbnb.com Calendar URL'
                  size='small'
                  variant='outlined'
                  fullWidth
                  multiline
                  defaultValue={accommodation.airbnbCalendarUrl}
                  onChange={handleChangeAirbnbCalendarUrl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='name'
                  label='Travelminit.ro Calendar URL'
                  size='small'
                  variant='outlined'
                  fullWidth
                  multiline
                  defaultValue={accommodation.travelminitCalendarUrl}
                  onChange={handleChangeTravelminitCalendarUrl}
                />
              </Grid>
            </Grid>
          </SectionCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='stretch'
            direction='column'
            spacing={2}
          >
            <Grid item xs={12}>
              <SectionCard title='Prices' variant='outlined'>
                <PriceList
                  mappingKey='priceToAccommodationId'
                  priceMappings={accommodation.prices}
                  prices={prices}
                  onAddNewPriceMapping={handleAddNewPriceMapping}
                  onDeletePriceMapping={handleDeletePriceMapping}
                  onChangePriceMapping={handleChangePriceMapping}
                />
              </SectionCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default AddAccommodationForm
