import React, { useEffect, useState } from 'react'
import { lightFormat } from 'date-fns'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Container, Grid } from '@mui/material'

import EnhancedTable from '../../components/table/enhanced-table'
import { PageLoader } from '../../components/page-loader'
import CustomerChip from '../../components/chip/customer-chip'
import TableHead from '../../components/table/table-head'

import { GET_CUSTOMERS } from '../../graphql/customers/queries'
import { useSettings } from '../../providers/settings-context-provider'


const CustomersListPage = () => {
  const rowsPerPage = 25
  const { setTitle } = useSettings()
  useEffect(() => setTitle('Customers'), [setTitle])

  const [customers, setCustomers] = useState([])

  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const sortBy = 'CREATEDAT'
  const sortDirection = 'desc'

  const { error, data, fetchMore, refetch, networkStatus } = useQuery(GET_CUSTOMERS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      take: rowsPerPage,
      orderBy: sortBy.toUpperCase(),
      orderDirection: sortDirection.toUpperCase()
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (data) {
      setCustomers(data.customers)
    }
  }, [data])

  const handleRowClick = (e, id) => {
    e.stopPropagation()

    navigate(`/customers/edit/${id}`)
  }

  const handleCreate = () => {
    navigate(`/customers/add`)
  }

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchMore({
      variables: {
        skip: (page + 1) * rowsPerPage,
        take: rowsPerPage
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        customers: [
          ...previousResult.customers, ...fetchMoreResult.customers
        ]
      }),
    })
  }

  const handleSort = (newSortBy, newSortDirection) => {
    setPage(0)
    refetch({
      orderBy: newSortBy.toUpperCase(),
      orderDirection: newSortDirection.toUpperCase(),
      skip: 0,
      take: rowsPerPage,
    })
  }

  const handleFilter = (filter, value) => {
    setPage(0)
    refetch({
      skip: 0,
      take: rowsPerPage,
      [filter]: value
    })
  }

  const renderLabel = (customer) => {
    if (customer.type === 'COMPANY') {
      return (
        <>
          <b>{customer.companyName}</b>&nbsp;{customer.firstName}&nbsp;
          {customer.lastName}
        </>
      )
    }

    return (
      <>
        {customer.firstName}&nbsp;
        <b>{customer.lastName}</b>
      </>
    )
  }

  const renderContact = (customer) => {
    return (
      <Grid container direction='column'>
        <Grid item>{customer.phone}</Grid>
        <Grid item>{customer.email}</Grid>
      </Grid>
    )
  }

  if (networkStatus === NetworkStatus.loading) return <PageLoader />
  if (error) return <div>Error!</div>

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      enableSorting: true,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Name',
    },
    {
      id: 'contact',
      numeric: false,
      disablePadding: false,
      enableSorting: false,
      label: 'Contact',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Created',
    },
    {
      id: 'updatedAt',
      numeric: false,
      disablePadding: false,
      enableSorting: true,
      label: 'Updated',
    },
  ]

  const rows = customers.map((customer) => {
    return {
      data: customer,
      id: {
        sortValue: customer.id,
        displayValue: customer.id,
      },
      name: {
        sortValue: `${customer.firstName} ${customer.lastName}`,
        displayValue: (
          <CustomerChip
            label={renderLabel(customer)}
            size='small'
            type={customer.type}
          />
        ),
      },
      contact: {
        sortValue: customer.email,
        displayValue: renderContact(customer),
      },
      createdAt: {
        sortValue: customer.createdAt,
        displayValue: lightFormat(new Date(customer.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      },
      updatedAt: {
        sortValue: customer.updatedAt,
        displayValue: lightFormat(new Date(customer.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
      },
    }
  })

  const filters = [
    {
      type: 'text',
      field: 'name',
      label: 'Name',
      default: '',
    },
    {
      type: 'select',
      field: 'type',
      label: 'Type',
      default: '-',
      options: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Individual',
          value: 'INDIVIDUAL',
        },
        {
          label: 'Company',
          value: 'COMPANY',
        },
      ],
    },
  ]

  return (
    <Container maxWidth={false} disableGutters>
      <TableHead
        filters={filters}
        handleFilter={handleFilter}
        handleCreate={handleCreate}
      />
      <EnhancedTable
        cells={headCells}
        rows={rows}
        onRowClick={handleRowClick}
        onSort={handleSort}
        onLoadMore={handleLoadMore}
        sortBy={sortBy}
        sortDirection={sortDirection}
        loading={networkStatus === NetworkStatus.fetchMore}
      />
    </Container>
  )
}

export default CustomersListPage
